.talents-headers-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #000;
    font-family:'Mulish';
}

.talents-headers-section h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.talents-headers-section span {
    align-self: flex-end;
    cursor: pointer;
    color: #666666;
    text-decoration: underline;
    font-size: 10px;
    font-weight: 400;
    margin-left: 12px;
    margin-bottom: 3px;
}

.talents-actions-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 14px;
}

.talents-actions-section button {
    margin-right: 8px;
}
.talents-actions-section button:last-child {
    margin-right: 0;
}

.talents-headers-custom-button{
    font-family: 'Mulish';
    height: 22px;
    font-weight: 400;
    font-size: 12px;
    color: #000;
    background-color: #FFF;
    padding: 0 8px;
    border-radius: 5px;
}

.talents-headers-custom-loading-button {
    padding: 0 !important;
    min-width: 30px !important;
}

.talents-headers-custom-loading-button span{
    margin: 0 !important;
}

.talents-custom-chat-iframe {
    width: 100%;
    height: 600px;
    border-radius: 12px;
}

.talents-custom-chat-iframe-container {
    margin-top: 40px;
}

.candiates-add-favorite-modal-loading {
    padding: 10px;
}

.candiates-add-favorite-modal-loading img{
    width: 300px !important;
}

.candiates-add-favorite-modal {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    padding: 10px;
    gap: 18px;
    width: 300px;
    font-family: 'Mulish';
    position: relative;
}

.candiates-add-favorite-modal-title {
    font-size: 12px;
    margin-top: 10px;
}

.candiates-add-favorite-modal-button {
    width: fit-content !important;
    border-radius: 5px !important;
}

.candiates-add-favorite-modal-autocomplete { 
    border-radius: 50px !important;
    background-color: #F4F4F4;
    border-color: #ECECEC !important;
    min-height: 30px !important;
    height: 30px !important;
}
