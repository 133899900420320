.wizard-warning-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 18px 22px;
    background-color: #fff;
    border-radius: 5px;
    outline: none;
}

.wizard-warning-modal-message {
    font-size: 14px;
    font-family: 'Mulish';
    color: #333333;
}

.wizard-warning-modal-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 18px;
}

.wizard-warning-modal-button {
    font-size: 14px;
    font-family: 'Mulish';
    padding: 8px 12px;
    border: 1px solid #333333;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
}

.wizard-warning-modal-button-cancel {
    background-color: #000;
    color: white;
}

.wizard-warning-modal-button-approve {
    color: #333333;
}

.wizard-warning-modal-button-approve:hover {
    background-color: #000;
    color: white;
}