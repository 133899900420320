.editor-placeholder {
  // background-color: brown; // good for debugging
  white-space: pre-line;
}

.sv_p_root {
  ul {
    list-style: disc;
    list-style-position: inside;
    padding-left: 2em;
  }
  ol {
    list-style: decimal;
    list-style-position: inside;
    padding-left: 2em;
  }
  a:link {
    color: blue;
  }
  a:visited {
    color: purple;
  }
}
