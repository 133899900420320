.report-qualifications 
.report-qualifications-single {
    display: flex;
    justify-content: space-between;
}

.report-qualifications 
.report-qualifications-single 
.report-qualifications-single-name {
    display: flex;
    width: 70%;
    text-align: center;
}

.report-qualifications 
.report-qualifications-single 
.report-qualifications-single-name img {
    padding: 10px;
}

.report-qualifications 
.report-qualifications-single 
.report-qualifications-single-name span {
    align-self: center;
    text-align: initial;
    padding-right: 5px;
}

.report-qualifications 
.report-qualifications-single
.report-qualifications-single-slider {
    width: 30%;
}

.report-qualifications 
.report-qualifications-single
.report-qualifications-single-score {
    text-align: center;
    width: 40px;
}