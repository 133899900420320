:root {
    --report-primary-color: #262626;
    --report-secondary-color: #FF6E30;
    --report-tertiary-color: #A1A1A1;
    --report-quaternary-color: #6E757C;
    --report-quinary-color: #F7ECE1;
    --report-senary-color: #F8F3EE;

    --report-success-color: #50B685;
    --report-fail-color: #F06969;

    --report-header-1-font-size: 26px;
    --report-header-2-font-size: 18px;
    --report-big-font-size: 16px;
    --report-regular-font-size: 14px;
    --report-small-font-size: 12px;
    --report-smaller-font-size: 10px;

    --report-font-family: "Mulish";

    --report-bold-font-weight: 600;
    --report-regular-font-weight: 400;
    --report-light-font-weight: 300;
    --report-lightest-font-weight: 200;
}
.candiate-info-and-insights-section {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.insights-section-wrapper {
    margin-left: 94px;
}

.our-advice-insights-section-wrapper {
    margin-top: 50px; 
}

.cv-highlights-wrapper {
    margin-top: 40px;
}

.report-section-header {
    font-size: 20px;
    color: var(--report-quaternary-color);
    padding: 50px 0;
}

.if-hired-section {
    margin-top: 50px;
}

.back-to-all-candidates-report-btn {
    font-size: var(--report-small-font-size);
    font-weight: var(--report-light-font-weight);
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 10px;
}

.print-mode-report-footer {
    display: none;
    display: flex;
    flex-direction: row;
}

.candidate-report-logo {
    margin: auto;
    padding: 50px 0;
}

.custom-chat-btn-candidate {
    font-family: 'Mulish';
    height: 22px;
    font-weight: 400;
    font-size: 12px;
    color: #000;
    background-color: var(--report-quinary-color);
    padding: 0 8px;
    border-radius: 5px;
}

.candidate-report-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.custom-chat-iframe {
    width: 100%;
    height: 600px;
    border-radius: 12px;
}

@media print {
    .report-layout-container {
        height: 100vh;
        flex-direction: column;
        margin-top: 0 !important;
    }

    .candidate-report-navigation-bar {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 50px;
    }

    .report-header-resources-cv a {
        font-size: var(--report-small-font-size);
        line-height: 18px;
    }

    /* .candidate-report-navigation-bar :last-child {
    } */

    .candidate-report-navigation-bar
    .candidate-report-navigation-bar-item {
        width: fit-content;
        /* transform: scale(0.8); */
        flex-direction: row;
        border: none !important;
    }

    .candidate-report-navigation-bar-item-a {
        /* text-align: left; */
        display: flex;
    }

    .candidate-report-navigation-bar-item-a
    .candidate-report-navigation-bar-item-icon {
        margin-right: 4px;
    }
    
    .report-layout-contnent {
        padding: 20px !important;
        margin: 0 auto;
    }

    .cv-highlights-wrapper {
        margin-top: 20px !important;
    }
    
    .report-header-resources-share span {
        font-size: var(--report-small-font-size);
    }

    .cv-highlights-section-skills-container {
        padding-left: 0 !important;
        margin-top: 28px !important;
        width: 100%;
    }

    .report-header-resources-cv img, .report-header-resources-share img {
        width: 14px !important;
    }

    .cv-highlights-insights-section {
        margin-top: 12px !important;
    }

    .candiate-info-and-insights-section {
        margin-top: 12px !important;
    }

    .candidate-report-logo {
        width: 80px;
        padding: 20px 0;
    }

    .our-insights-insight {
        padding: 4px 20px 4px 8px;
        flex-direction: column;
    }

    .our-advice-insights-section-wrapper {
        margin-top: 12px !important;
    }

    .insights-section-wrapper {
        margin-left: 38px;
    }
    
    .cv-highlights-headers-container {
        width: 188px;
    }
    
    .cv-and-qualifiqation-pdf-container {
        max-width: unset !important;
    }
}