.building-block-editor-main-header {
    font-family: 'Mulish';
    font-size: 20px;
}

.building-block-editor-form-section {
    margin-top: 31px;
    font-family: 'Mulish';
}

.building-block-editor-form-section h2{
    font-size: 16px;
}


.building-block-editor-form-section > * {
    margin-bottom: 16px !important;
}

.image-uploader-custom {
    display: flex;
    flex-direction: row;
    font-family: 'Mulish';
    align-items: center;
}

.image-uploader-custom 
.image-uploader-custom-texts {
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.image-uploader-custom 
.image-uploader-custom-texts p {
    font-size: 12px;
}

.image-uploader-custom 
.image-uploader-custom-texts span {
    font-size: 10px;
    color: #999999;
}

.building-block-editor-actions-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.building-block-editor-settings-section {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 24px 0;
}

.building-block-editor-settings-section > div {
    flex: 1;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.loading-custom-backdrop { 
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}