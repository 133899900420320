.report-category {
  margin-top: 40px;
  display: flex;
}

.report-category .report-category-title p{
  font-size: 12px;
  width: 200px;
  color: #a1a1a1;
}

.report-category .report-category-title h3 {
  font-weight: 400;
  color: #262626;
  font-size: 26px;
}

.report-category .report-category-content {
  width: 75%;
  padding-left: 50px;
}