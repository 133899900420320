.synergy-table-cards-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.synergy-table-card {
    border-radius: 30px;
    background-color: var(--report-quinary-color);
    width: 480px;
}

.synergy-positive-color {
    color: var(--report-success-color)
}

.synergy-negative-color {
    color: var(--report-fail-color)
}

.synergy-table-card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px 30px;
}

.synergy-table-card-top h3 {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-bold-font-weight);
    color: var(--report-primary-color);
    max-width: 220px;
}

.synergy-table-card-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 22px;
    border-top: 2px solid #F8F3EE;
}

.synergy-table-card-item img {
    margin-right: 10px;
}

.synergy-table-card-item span {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-light-font-weight);
    color: var(--report-primary-color);
    line-height: 18px;
}

.synergy-forcast-sentence-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 26px;
    border-radius: 30px;
    width: 90%;
    margin-top: 42px;
    background-color: var(--report-quinary-color);
    margin-inline: auto;
}

.synergy-forcast-sentence-container p {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-light-font-weight);
    color: var(--report-primary-color);
    line-height: 18px;
}

.synergy-forcast-sentence-container p span {
    font-weight: var(--report-bold-font-weight);
}