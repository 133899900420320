.hr-corner-sections-navigator {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 44px;
}

.hr-corner-sections-navigator > span {
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.hr-corner-sections-navigator-active {
    font-weight: 600 !important;
    text-decoration: underline;
}

.hr-corner-current-section-container {
    margin-top: 40px;
    position: relative;
}