@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');

.navigation-bar-links {
    display: flex;
    flex-direction: row;
}

.navigation-bar-links
.navigation-bar-links-logo {
    height: fit-content;
    cursor: pointer;
    align-self: center;
}

.navigation-bar-links
.navigation-bar-links-list {
    list-style: none !important;
    display: flex;
    flex-direction: row;
}

.navigation-bar-links
.navigation-bar-links-list 
.navigation-bar-links-list-single {
    height: fit-content;
    align-self: center;
}

.navigation-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 36px 36px;
    padding-top: 46px;
}

.custom-link-button {
    font-family: 'Mulish' !important;
    text-transform: none !important;
    font-weight: 300 !important;
    color: #000 !important;
}

.custom-link-button * {
    text-decoration: none !important;
    color: #000 !important;
    font-weight: 300;
    font-size: 12px;
    margin-right: 6px;
}

.custom-link-button:hover {
    background-color: transparent !important;
}

.subitems-links-list {
    list-style: none !important;
    padding: 0 20px !important;
}

.subitems-links-list .subitems-links-single {
    border-bottom: 1px solid rgba(223, 224, 235, 0.3);
}

.subitems-links-list .subitems-links-single a{
    font-family: 'Mulish';
    color: #000 !important;
    font-weight: 300;
    margin-right: 5px;
    text-decoration: none;
    font-size: 12px;
    line-height: 40px;
}

.navigation-bar-actions-section {
    height: fit-content;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    /* width: 340px; */
}

.items-active-link {
    font-weight: 700;
}

.navigations-items-link {
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}

.navigations-items-link:hover {
    border-bottom: 2px solid #000;
}

.subitems-active-sublink { 
    font-weight: 700 !important;
}

.navigation-bar-actions-quiz-button {
    padding-left: 6px !important;
    padding: 5px 16px;
}

.navigation-bar-actions-quiz-button-icon {
    width: 28px;
    height: 28px;
    margin-right: 6px !important;
}

.navigation-bar-actions-quiz-button-wrapper {
    transition: all 0.3s ease-in-out;
}