.lnrd-search > div[class*="control"] {
  padding-left: 40px;
}

.wizard-form__single-value,
.wizard-form__placeholder {
  position: static;
  transform: none;
  max-width: none;
}

// *[class^='position-async-select'] {}

.position-async-select__control {
  flex-grow: 1;
  color: black;
}
.position-async-select__input {
  display: inline-block;
  flex-grow: 1;
}

.position-async-select-bordered__control {
  flex-grow: 1;
  color: black;
}

.position-async-select-bordered__input {
  display: inline-block;
  flex-grow: 1;
}

.position-async-select-bordered__option, .position-async-select-bordered__single-value{
  white-space: pre-wrap !important;
}