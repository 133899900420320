.Classic {
    background-size: cover;
    background-image: url('/src/assets/images/Classic.png');
}
.TheUnboxableExperience {
    background-size: cover;
    background-image: url('/src/assets/images/TheUnboxableExperience.png');
}

.preview-step-container {
    display: flex;
    flex-direction: row;
}

.preview-image-section-container {
    margin-top: 18px;
    height: 250px;
    margin-bottom: 20px;
}

.preview-image-container {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 12px;
    border: 1px solid #a7a7a740;
}

.preview-image-button {
    width: 126.03px;
    height: 42.32px;
    background: rgba(225, 225, 225, 0.8);
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.2);
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.preview-image-large-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    width: 80%;
    height: 80%;
}