main#review {
  div[role="progressbar"] > div > div > span {
    font-weight: normal !important;
  }

  // Classes
  .bg-gray-rv {
    background-color: #4F4F4F;
  }

  .bg-gray-rv-2 {
    background-color: #333;
  }

  .bg-gray-rv-3 {
    background-color: #EDEDED;
  }

  .bg-orange-rv {
    background-color: #F3AD81;
  }

  .text-orange-rv {
    color: #F3AD81;
  }

  .rv-padding {
    @apply px-36;

    @media (max-width: 768px) {
      & {
        @apply px-16;
      }
    }
  }

  .rv-padding-l {
    @apply pl-36;

    @media (max-width: 768px) {
      & {
        @apply pl-16;
      }
    }
  }

  .rv-padding-r {
    @apply pr-36;

    @media (max-width: 768px) {
      & {
        @apply pr-16;
      }
    }
  }

  img {
    max-width: initial !important;
  }
}
