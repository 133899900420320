.report-layout-container {
    font-family: var(--report-font-family);
    color: var(--report-primary-color);
    background-color: #F8F3EE;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 96vh;
    overflow-y: scroll;
    border-radius: 12px;
    width: 100%;
    margin: auto;
    margin-top: 8px;
}

.report-layout-contnent {
    padding: 14px 30px;
    height: max-content;
    width: 100%;
    background-color: #F8F3EE;
}
