.general-tab-container {
    display: flex;
    flex-flow: column;
}

.general-tab-container > * {
    margin-bottom: 22px;
}

.general-tab-row {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.general-tab-row > * {
    width: 45%;
}

.general-tab-title-image-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.leaders-tab-data-image-upload-general {
    height: 45px;
    width: 170px;
    /* border-radius: 12px; */
}

.leaders-tab-data-image-upload-general > 
.image-upload-wrapper {
    padding: 12px !important;
    border-radius: 12px !important;
}

.leaders-tab-data-image-upload-general > img {
    width: 170px;
    height: 45px;
    background-size: cover;
    border-radius: 12px !important;
}

.custom-textfield-size {
    width: 400px !important;
}