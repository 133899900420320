.error-message-container {
    font-size: 14px;
    font-family: 'Mulish';
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hiring-statistics-iframe { 
    border-radius: 5px;
    background-color: #FFF;
}