.custom-filter-buttons {
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 12px !important;
    background-color: #fff !important;
    color: #000 !important;
    text-transform: none !important;
    display: flex;
    flex-direction: row;
    margin-right: 8px !important;
}

.custom-filter-buttons span {
    margin-right: 10px;
}

.table-toolbar {
    margin-bottom: 10px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}