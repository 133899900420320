.discovey-editor-skills-input-wrapper {
    border: none !important;
    padding: 0 !important;
    padding-bottom: 10px !important;
}

.discovery-wizard-skills-suggestion {
    margin: 0;
    margin-top: 4px;
}

.discovey-editor-skills-input {
    border: 1px solid #333 !important;
    height: 32px;
    align-self: center;
    border-radius: 50px;
    padding: 4px 10px !important;
}

.discovery-editor-adventage-skills-wrapper {
    margin-top: 10px;
}