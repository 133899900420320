.table-search-container {
    width: 100%;
    display: flex; 
    justify-content: flex-end;
    justify-content: space-between;
    height: 49px;
}
.table-search-container 
.MuiTextField-root{
    height: 42px;
}

.table-search-container input{
    padding: 0;
}

.table-search-container fieldset {
    height: unset !important;
}