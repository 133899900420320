.candidate-name-and-rank-container {
    display: flex;
    flex-direction: row;
}

.candidate-rank {
    margin-left: 16px;
    font-size: var(--report-smaller-font-size);
    font-weight: var(--report-light-font-weight);
    align-self: center;
}

.candidate-name-definition {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--report-primary-color);
    padding: 6px 18px;
    border-radius: 20px;
    position: relative;
}

.candidate-name {
    font-size: var(--report-header-2-font-size);
    font-weight: var(--report-bold-font-weight);
}

.candidate-name span{
    color: var(--report-secondary-color);
    margin-left: 4px;
}

.question-icon {
    font-size: 17px !important;
    position: absolute;
    left: 95%;
    bottom: 25px;
    cursor: 'pointer';
}

.candidate-relevancy-tooltip {
    padding: 8px;
}

.candidate-relevancy-tooltip p {
    font-size: var(--report-small-font-size);
    font-family: 'Mulish';
    font-weight: var(--report-light-font-weight);
}

.candidate-relevancy-tooltip span {
    font-size: var(--report-small-font-size);
    font-family: 'Mulish';
    font-weight: var(--report-regular-font-weight);
}