
.recommendation-card {
    flex: 1;
    min-height: 210px;
    height: auto;   
    border-radius: 10px;
    padding: 18px;
    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.05);
    margin-right: 24px;
    max-width: 33.33%;
    overflow: overlay;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
}

.recommendation-card:last-of-type {
    margin-right: 0 !important;
}

.recommendation-card-bg-white {
    background-color: #FFFF !important;
}

.recommendation-card-bg-orange {
    background-color: #F7995E !important;
}

.recommendation-card-color-white {
    color: #FFFF !important;
}

.recommendation-card-color-black {
    color: #000 !important;
}

.recommendation-card
.recommendation-card-headers {
    display: flex;
    flex-direction: row;
}

.recommendation-card
.recommendation-card-headers h1 {
    font-family: 'Mulish';
    font-weight: 700;
    font-size: 14px;
    align-self: center;
    padding-left: 10px;
}

.recommendation-card
.recommendation-card-headers h1 span {
    font-family: 'Mulish';
    font-weight: 300;
    font-size: 14px;
    align-self: center;
}

.recommendation-card
.recommendation-card-candidates-list {
    margin-top: 20px !important;
    font-family: 'Mulish';
    font-size: 12px;
    max-width: 100%;
    margin-right: 0 !important;
    list-style: none !important;
    padding-right: 16px !important;
    padding-left: 2px !important;
    padding-bottom: 40px !important;
    width: 100%;
}

.recommendation-card
.recommendation-card-candidates-list li {
    width: 100%;
}

.recommendation-card
.recommendation-card-candidates-list li
.recommendation-card-candidates-list-single {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    justify-content: space-between;
}

.recommendation-card
.recommendation-card-candidates-list li span {
    cursor: pointer;
    font-size: 14px;
    max-width: 74%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.recommendation-card
.recommendation-card-candidates-list li
.recommendation-card-candidates-list-row-buttons {
    width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.recommendation-card
.recommendation-card-candidates-list li
.recommendation-card-candidates-list-row-buttons > * {
    margin-right: 4px;
}

.recommendation-card-tooltip-text {
    font-family: 'Mulish';
    font-weight: 200;
    color: #000;
    padding: 8px;
}

.tooltip-icon-btns {
    font-family: 'Mulish';
    font-weight: 200;
}

.reccomendation-card-loading {
    position: absolute !important;
    width: 100%;
    left: 0;
    bottom: 0px;
    background-color: transparent !important;
    height: 2px !important;
}

.recommendation-card-loading-primary
.MuiLinearProgress-bar {
    background-color: #F7995E !important;
}

.recommendation-card-loading-secondary
.MuiLinearProgress-bar {
    background-color: #FFFF !important;
}

.recommendation-card-position-name {
    font-weight: 200;
    font-size: 12px !important;
}

.no-candidates-message-container {
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
}

.no-candidates-message {
    font-size: 12px;
    font-family: 'Mulish';
}

.pagination-nav-container {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    width: 40px !important; */
    height: 10px;
}

.pagination-nav-btn {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    cursor: pointer;
    transform: scale(.33);
    position: relative;
}

.pagination-nav-btn-hide {
    display: none !important;
}

.pagination-nav-btn-primary {
    background-color: #0004;
}

.pagination-nav-btn-secondary {
    background-color: #ffc7a4;
}


.pagination-nav-btn-selected-primary {
    background-color: #5B7BFF !important;
    transform: scale(1) !important;
    opacity: 1 !important;
}

.pagination-nav-btn-selected-secondary {
    background-color: #FFFF !important;
    transform: scale(1) !important;
    opacity: 1 !important;
}


@media only screen and (max-width: 1366px) {
    .recommendation-card { 
        min-height: 190px;
    }
}