@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.container1 h5 > span.sv-string-viewer, h4 > span.sv-string-viewer {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130.19%;
}
    
.container1  .quit-cp-wrap {
    display: contents;
    color: #BBB9B9;
    font-size: 14px;
}

.quit-cp-wrap span{
    margin: 4px;
    margin-bottom: 0px;
}
.container1 .quit-cp-wrap span:hover{
    color: #000;
    cursor: pointer;
}
.container1 .quit-cp-wrap .icon-tooltip-wrap {
    display: flex;
}
.container1 .quit-cp-wrap .icon-tooltip-wrap:hover {
    color: #000;
}

.container1 .quit-cp-wrap .MuiSvgIcon-root {
    font-size: 16px;
    margin: 4px;
    margin-bottom: 0px;
}

.container1 .quit-cp-wrap .info-tolltip {
    visibility: hidden;
    position: absolute;
    top: 53px;
    right: 35px;
    z-index: 1;
    background-color: #FFF;
    border: solid 1px #C4C4C4;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 0px 5px;

}

.container1 .icon-tooltip-wrap:hover .info-tolltip {
    visibility: visible;
}

.container1 .cp-top-progress-bar-wrap {
    margin-left: 27px;
}

.container2 h5 > .sv-string-viewer {
    text-align: left;
}

@media (max-width: 600px) {
    .container1 h5 > span.sv-string-viewer, h4 > span.sv-string-viewer {
        font-size: 16px;
    }

    .container1 .sv_qstn > div {
        margin-bottom: 10px;
    }

    .container1 .com-profile-matrix .input-span {
        display: none;
    }

    .container1 .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.table.sv_q_matrix.com-profile-matrix thead {
        display: block;
    }

    .container1 table.sv_q_matrix.com-profile-matrix > thead > tr {
        justify-content: flex-end;
    }

    .container1 .table.sv_q_matrix.com-profile-matrix th {
        min-width: 50px;
    }

    .container1 table.sv_q_matrix.com-profile-matrix > thead > tr > th > span.sv-string-viewer {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 130.19%;
    }

    .container1 table.sv_q_matrix.com-profile-matrix > thead > tr > th:nth-child(3) > span.sv-string-viewer {
        padding-left: 8px;
    }
    
    .container1 .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.table.sv_q_matrix.com-profile-matrix tr {
        display:  flex;
        border: none;
        padding: 0;
        padding-bottom: 3px;
    }

    .container1 .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.table.sv_q_matrix.com-profile-matrix td {
        display: flex;
        align-items: center;
    }

    .container1 .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.sv_q_matrix.com-profile-matrix td:first-of-type {
        display: block;
    }

    .container1 .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.sv_q_matrix.com-profile-matrix td:nth-child(2) {
        max-width: 85px;
        min-width: 70px;
    }

    .container1 .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.sv_q_matrix.com-profile-matrix td::after {
        color:transparent;
        height: 1px;
    }

    .container1 .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.sv_q_matrix.com-profile-matrix tbody td:first-of-type {
        width: 182px;
    }

    .container1 table.sv_q_matrix.com-profile-matrix > tbody > tr > td.sv_q_m_cell > span.sv-string-viewer {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130.19%;
    }

    .container1 .sv_qstn .com-profile-matrix label.sv_q_m_label {
        width: auto;
        position: relative;
        left: 50px;
        margin-left: 0px !important;
    }

    .container1 .role-name.cp-title {
        margin-top: 5px;
    }

    .container1 .quit-cp-wrap .info-tolltip {
        right: 0px;
    }

    .container2.with-instructions .sv_container {
        margin-top: 0px;
    }
}

@media (min-width:600px)  { 
    .MuiDialog-paper {
        width: 600px;
    }

    .container1 .sv_q_matrix.com-profile-matrix {
        margin-top: 0px;
    }

    .sv_qstn table.sv_q_matrix.com-profile-matrix td.sv_q_m_cell:last-of-type{
        width: 20px;
        min-width: 20px;
    }

    .container1 table.sv_q_matrix.com-profile-matrix > tbody > tr > td.sv_q_m_cell:nth-child(2) > .sv_q_m_label {
        right: 25px;
    }

    .container1 table.sv_q_matrix.com-profile-matrix > tbody > tr > td.sv_q_m_cell > span.sv-string-viewer {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130.19%;
    }

    .container1 table.sv_q_matrix.com-profile-matrix > thead > tr > th > span {
        display: none;
    }

    .container1 .com-profile-matrix .sv_q_m_label input[type='radio']:after {
        width: 60px;
        height: 35px;
        border-radius: 5px;
        margin-top: -9px;
        margin-left: 3px;
        background-color: #FFF;
        border: solid 1px #C4C4C4;
        
    }

    .container1 .com-profile-matrix .sv_q_m_label input[type='radio'].least:after {
        margin-left: 4px;
        
    }

    .container1 .com-profile-matrix .sv_q_m_label input[type='radio'].best:after {
        margin-left: 2px;
    }
    .container1 .com-profile-matrix .sv_q_m_label input[type='radio']:checked:after {
        width: 60px;
        height: 35px;
        border-radius: 5px;
        background-color: #333;
        border-color: #333;
    }

    .container1 .com-profile-matrix label.sv_q_m_label {
        max-width: 60px ;
    }

    .container1 .com-profile-matrix .input-span {
        display: block;
        z-index: 1;
        color: #000;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        text-transform: capitalize;
    }

    .container1 .com-profile-matrix .sv_q_m_label.checked .input-span {
        color: #FFF;
    }
}



