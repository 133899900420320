.chat-iframe {
    width: 450px;
    height: 600px;
}

.chat-button {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    position: relative;
}

.chat-button svg {
    color: #B0B0B0;
}

.chat-button::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: green;
    position: absolute;
    top: 7px;
    right: 5px;
}

.chat-context-header {
    font-size: 18px;
    font-family: 'Mulish';
    padding: 16px 12px;
}