.recommendation-table-container {
    position: relative;
}

.add-recommendation-button {
    border-radius: 5px;
    margin-left: auto;
    height: 35px;
    position: absolute;
    right: 0;
}

.recommendations-filter-section {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 14px;
}

.recommendation-filter-dropdwon {
    width: 140px !important;
    border-radius: 5px !important;
}