.ai-center-container {
  font-family: var(--report-font-family);
  width: 658px;
  min-height: 400px;
  padding: 0px 22px;
  margin-bottom: 20px;
  text-align: center;
}

.response-container {
  margin-top: 20px;
  font-size: 16px;
  text-align: left;
}

.search-bar {
  width: 80%;
  margin: 40px auto 20px;
}

.response-card {
  margin-top: 20px;
}

.suggested-questions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.question-bubble {
  padding: 10px;
  border: 1px solid black;
  border-radius: 25px; /* Makes the bubble round */
  cursor: pointer; /* Indicates the bubble is clickable */
  font-size: 15px;
  transition: background-color 0.5s ease; /* Slow transition for background color */

  /* Hover effect */
  &:hover {
    background-color: #fcccb7;
  }
}
