.hrm-quiz-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 150px;
    width: 658px;
    margin: auto;
}

.hrm-quiz-header {
    font-size: 22px;
    font-weight: 300;
}

.hrm-quiz-sentence-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 38px;
    align-items: center;
}

.hrm-quiz-sentence {
    color: #F69763;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
}

.hrm-quiz-start-quiz-button {
    background-color: #F7995E;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 114px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    margin-top: 40px;
}

.hrm-quiz-skip-sentence {
    color: #333;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px;
}

.hrm-quiz-iframe {
    min-height: 720px;
    min-width: 700px;
    width: 1200px;
    margin: auto;
}

.hrm-quiz-plug-animation {
    margin-right: 8px !important;
}