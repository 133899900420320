.talent-report-qustions-answers-section {
    margin-top: 48px;
}

.talent-report-qustions-answers-section h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
}

.talent-report-qustions-answers-head {
    border-top: 1px solid #262626;
    border-bottom: 1px solid #262626;
    padding: 15px 0;
    margin-bottom: 28px;
}

.talent-report-qustions-answers-head-content {
    display: flex;
    flex-flow: row;
}

.talent-report-qustions-answers-head-content h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 16.2px;
    padding: 0 15px;
    /* width: 180px; */
}

.talent-report-qustions-answers-head-content div {
    width: 1.8px;
    height: 20px;
    background-color: #262626;
}


.talent-report-qustions-answers-head-content span {
    font-style: normal;
    font-weight: 400;
    font-size: 16.2px;
    padding: 0 15px;
}

.talent-report-qustions-answers-seperate-line {
    width: 100%;
    height: 0.5px;
    background-color: #262626;
    margin-bottom: 20px;
}

.talent-report-qustions-answers-q-n-a {
    display: flex;
    font-weight: 300;
    color: #262626;
    max-width: 700px;
}

.talent-report-qustions-answers-number {
    font-size: 16.2px;
    margin-right: 6px;
}

.talent-report-qustions-answers-question-type {
    font-size: 12px;
    font-weight: 700;
    color: #b3b3b2;
    margin-bottom: 8px;
}

.talent-report-qustions-answers-q-n-a-content {
    display: flex;
    flex-flow: column;
    margin-bottom: 48px;
}

.talent-report-qustions-answers-q-n-a-qustion {
    line-break: auto;
    display: flex;
    flex-direction: row;
}

.talent-report-qustions-answers-q-n-a-qustion 
.talent-report-qustions-answers-q-n-a-qustion-text {
    margin-left: 20px;
    font-weight: 300;
}

.talent-report-qustions-answers-q-n-a-answer {
    margin-top: 20px;
    font-weight: 300;
    display: flex;
    flex-flow: column;
    margin-left: 20px;
    width: 100%;
}

.talent-report-qustions-answers-q-n-a-answer-text {
    line-break: anywhere;
    max-width: 100%;
}

.talent-report-qustions-answers-q-n-a-answer pre {
    font-size: 13px;
}

.talent-report-qustions-answers-q-n-a-content-header-3{
    font-weight: 800;
    font-size: 12px;
}

.talent-report-qustions-answers-q-n-a-answer h3{
    margin-bottom: 6px;
}

.talent-report-qustions-answers-q-n-a-answer pre {
    white-space: break-spaces;
}

.report-qa-custom-list {
    padding: 0px 20px !important;
}

.report-qa-custom-list .correct-incorrect-container img{
    align-self: baseline;
}