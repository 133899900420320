.report-synergy-talent-hrm-container {
    font-family: 'Mulish';
    width: 86%;
    margin-top: 100px;
    background-color: var(--report-quinary-color);
    border-radius: 24px;
    margin-inline: auto;
}

.report-synergy-talent-hrm-container
.synergy-talent-hrm-description {
    display: flex;
    padding: 24px 36px;
}

.report-synergy-talent-hrm-container
.synergy-talent-hrm-description p {
    margin-left: 23px;
    font-weight: 300;
    font-size: var(--report-big-font-size);
    color: var(--report-primary-color);
}

.report-synergy-talent-hrm-container
.synergy-talent-hrm-description p span {
    font-weight: var(--report-bold-font-weight);
}

.report-synergy-talent-hrm-container
.synergy-talent-hrm-title {
    font-size: var(--report-big-font-size);
    font-weight: 300;
    line-height: 24px;
    padding: 15px 21px;
    border-top: 1px solid #fff;
}

.report-synergy-talent-hrm-container
.synergy-talent-hrm-title span {
    font-weight: var(--report-bold-font-weight);
    color: var(--report-success-color);
}

.report-synergy-talent-hrm-container 
.talent-report-synergy-table {
    border-collapse: collapse;
    border: none;
    width: 100%;
}

.report-synergy-talent-hrm-container 
.talent-report-synergy-table td {
    border: 1px solid #fff;
    padding: 11px 21px;
}

.report-synergy-talent-hrm-container 
.talent-report-synergy-table tr td:first-child {
    border-left: none;
    border-bottom: none;
}

.report-synergy-talent-hrm-container 
.talent-report-synergy-table tr td:last-child {
    border-right: none;
    border-bottom: none;
}
.report-synergy-talent-hrm-container 
.talent-report-synergy-table td div {
    display: flex;
}

.report-synergy-talent-hrm-container 
.talent-report-synergy-table td div img {
    margin-right: 12px;
}

.report-synergy-talent-hrm-container 
.talent-report-synergy-table 
.table-cell-content span {
    font-weight: 300;
}