.reccomendation-center-container {
    font-family: var(--report-font-family);
    width: 658px;
    min-height: 400px;
    padding: 0px 22px;
    margin-bottom: 20px;
}

.reccomendation-center-header-container {
    margin-top: 14px;
}

.reccomendation-center-header {
    font-size: 20px;
}

.reccomendation-center-explenation { 
    font-size: var(--report-regular-font-size);
    font-weight: var(--report-light-font-weight);
    line-height: 20px;
    margin-top: 10px;
    width: 80%;
}

.recommendation-center-add-recommendation-btn {
    border-radius: 5px !important;
    margin-top: 15px;
    margin-bottom: 15px;
}

.reccomendation-center-reccomendations {
    padding-bottom: 4px;
}

/* ReccomendationAccordion */

/* .reccomendation-accordion-tips {
    background-color: var(--report-senary-color);
} */

.reccomendation-accordion-tips ul {
    list-style: disc !important;
    padding-left: 16px !important;
}

.reccomendation-accordion-tips ul li {
    margin-bottom: 8px;
    font-weight: var(--report-light-font-weight);
}

.reccomendation-card-container {
    margin-bottom: 18px;
    border-radius: 12px;
}

.reccomendation-card-reccomendation-hr {
    display: inline-flex;
    gap: 20px;
}

.reccomendation-card-reccomendation-hr
.reccomendation-card-reccomendation-image {
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin-top: 6px;
    /* background-color: #FFF; */
    border-radius: 50%;
}

.reccomendation-card-reccomendation-hr-message {
    position: relative;
}

.reccomendation-card-section-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.reccomendation-card-section-title {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-bold-font-weight);
    margin-bottom: 10px;
    text-transform: capitalize;
}

.reccomendation-card-reccomendations-container {
    transition: max-height 0.30s ease-in-out;
    overflow: hidden;
}

.reccomendation-card-reccomendations-container ul {
    transition: max-height 0.30s ease-in-out;
}

.reccomendation-card-reccomendations-container-close ul {
    max-height: 0;
    /* display: none; */
}

.reccomendation-card-reccomendations-container-open ul{
    max-height: 9000px;
    /* display: block; */
}

.reccomendation-card-reccomendations {
    padding-left: 0px !important;
    list-style: disc !important;
}

.reccomendation-card-reccomendations li {
    margin-bottom: 8px;
    font-size: var(--report-regular-font-size);
    font-weight: var(--report-light-font-weight);
}

.reccomendation-card-reccomendations li strong {
    font-weight: 700;
}

.reccomendation-card-add-comment-btn {
    font-size: var(--report-small-font-size);
    color: var(--report-secondary-color);
    cursor: pointer;
}

.reccomendation-card-reccomendation {
    display: flex;
    flex-flow: column;
    padding: 12px 10px;
    border-radius: 12px;
    gap: 10px;
    background-color: var(--report-senary-color);
}

.reccomendation-card-reccomendation-user-details {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.reccomendation-card-reccomendation-user-details span {
    font-weight: var(--report-light-font-weight);
    font-size: var(--report-small-font-size);
}

.reccomendation-card-reccomendation-image {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    font-weight: var(--report-bold-font-weight);
    display: flex;
    align-items: center;
    justify-content: center;
}


.reccomendation-image-blue {
    background-color: #5a7bff !important;
}