@keyframes animate-fade-in {
    0% {
        opacity: 0;
    }    
    100% {
        opacity: 1;
    }
}


.animate-fade-in {
    animation: animate-fade-in 0.5s ease-in;
}