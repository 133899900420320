.rank-open-questions-container {
    padding: 20px 28px;
    font-family: var(--report-font-family);
    max-width: 700px;
    min-width: 650px;
}

.rank-open-questions-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #ffffff;
}
.rank-open-questions-loading img {
    width: 300px !important;
}

.rank-open-questions-header {
    font-size: 20px;
}

.rank-open-questions-question-container {
    /* margin-top: 28px; */
}

.rank-open-questions-question-header {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-bold-font-weight);
    color: var(--report-primary-color)
}

.rank-open-questions-question-candidate {
    font-size: var(--report-regular-font-size);
    font-weight: var(--report-regular-font-weight);
    color: var(--report-primary-color)
}

.rank-open-questions-answer-container {
    margin-top: 20px;
}

.rank-open-questions-answer-header {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-bold-font-weight);
    color: var(--report-secondary-color);
    margin-left: 18px;
}

.rank-open-questions-answer-candidate {
    font-size: var(--report-regular-font-size);
    font-weight: var(--report-light-font-weight);
    padding-left: 18px;
    border-left: 1px solid var(--report-secondary-color);
    line-height: 24px;
    color: var(--report-primary-color);
    width: 96%;
}

.rank-open-questions-feedback-container {
    margin-top: 60px;
}