.ats-mail-section-mail-inputs-container {
    display: flex;
    flex-direction: column;
}


.ats-mail-section-mail-inputs-container > * {
    margin-bottom: 18px;
}

.recipients-step-hrm-section {
    display: flex;
    flex-direction: column;
}

/* .recipients-step-hrm-input-or-card {
    width: 220px;
} */

.recipients-step-hrm-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.recipients-step-hrm-input-button {
    margin-left: 8px;
}