.candidate-report-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.report-header-position-recomadation {
    display: flex;
    flex-direction: row;
}

.report-header-position-recomadation 
span {
    margin-right: 4px;
    font-size: var(--report-small-font-size);
    font-weight: var(--report-light-font-weight);
    color: var(--report-primary-color)
}

.report-header-position-recomadation 
.report-header-position-name {
    color: var(--report-secondary-color);
}

.report-header-resources {
    display: flex;
    flex-direction: row;
}

.report-header-resources-cv,
.report-header-resources-share {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.report-header-resources-cv a {
    font-size: var(--report-small-font-size);
    font-weight: var(--report-light-font-weight);
    text-decoration: none;
    color: var(--report-primary-color);
}

.report-header-resources-share span {
    font-size: var(--report-small-font-size);
    font-weight: var(--report-light-font-weight);
    color: var(--report-primary-color);
}

.report-header-resources-cv img,
.report-header-resources-share img {
    margin-right: 11px;
}

.report-header-resources-cv {
    margin-right: 36px;
}