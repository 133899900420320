.cke_chrome {
    display: block !important;
    border: 1px solid #000 !important;
    padding: 4px !important;
    border-radius: 12px !important;
}

.cke_top {
    background-color: #fff !important;
    border: none !important;
}

.cke_bottom {
    display: none !important;
}

.richtext-wrapper {
    margin-top: 10px;
}