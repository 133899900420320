
.layout-custom {
    /* background-color: #E5E5E5; */
    min-height: 100vh;
    /* overflow-y: auto; */
}

.layout-custom-page-container {
    padding: 0 0;
    color: #000;
    max-width: 1366px;
    margin: auto;
    min-height: 100%;
    padding-bottom: 20px;
    /* width: fit-content; */
}

.layout-bg-grey {
    background-color: #F4F4F4;
}

.layout-bg-white {
    background-color: #fff;
}

.chat-button-custom {
    position: fixed;
    right: 10px;
    bottom: 10px;
    padding: 16px;
}

@media only screen and (max-width: 1366px) {
    .layout-custom-page-container {
        padding: 0 118px;
        padding-bottom: 20px;
        max-width: unset;
    }
}

@media print {
    .layout-custom-page-container {
        padding-bottom: 0px !important;
    }
}