.custom-auto-complete {
    background-color: #fff;
    border: 1px solid #999999;
    border-radius: 12px;
    min-height: 45px;
    width: 300px;
}

.custom-show-more-chip {
    display: flex;
    align-items: center;
    height: 24px;
    margin: 4px;
    line-height: 22px;
    background-color: #999999;
    border-radius: 50px;
    box-sizing: content-box;
    padding: 6px 18px;
    outline: 0;
    overflow: hidden;
    cursor: pointer;
    font-size: 12px;
    color: #fff;
}

.styled-autocomplete-wrapper {
    margin-top: 10px;
}