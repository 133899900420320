.create-new-company-container {
    padding: 10px 18px;
    display: flex;
    flex-direction: column;
}

.create-new-company-container
.create-new-company-header {
    font-family: 'Mulish';
    font-weight: 300;
}

.create-new-company-container
.create-new-company-error-section {
    height: 32px;
    display: flex;
    align-items: center;
}

.create-new-company-container
.create-new-company-error-section span {
    color: red;
    padding: 10px 0;
}

.create-new-company-container
.create-new-company-form {
    display: flex;
    flex-direction: column;
}

.create-new-company-container
.create-new-company-form button {
    width: 80px;
    padding: 5px 10px;
    margin: auto;
}

.create-new-company-container
.create-new-company-form
.create-new-company-form-input {
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
}

.create-new-company-container
.create-new-company-form
.create-new-company-form-input
.MuiFormControl-root {
    align-self: flex-end;
    margin-left: 8px;
}

.create-new-company-container
.create-new-company-form
.create-new-company-form-input input {
    height: 0.6em;
}