
    .bg-gray-rv {
      background-color: #4F4F4F;
    }
  
    .bg-gray-rv-2 {
      background-color: #333;
    }
  
    .bg-gray-rv-3 {
      background-color: #EDEDED;
    }
  
    .bg-orange-rv {
      background-color: #F3AD81;
    }
  
    .text-orange-rv {
      color: #F3AD81;
    }
  
    img {
      max-width: initial !important;
    }
  
  