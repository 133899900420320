.recommendation-form-container {
    padding: 16px 33px;
    font-family: 'Mulish';
    width: clamp(200px, 702px, 702px);
}

.recommendation-form-title {
    font-size: 18px;
}

.recommendation-form-description {
    font-size: 12px;
    margin-top: 4px;
}

.recommendation-form-description-hr-list {
    list-style: disc !important;
}

.recommendation-form-form-elements {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;

}

.recommendation-form-dropdowns-container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 18px;
    height: fit-content;
    opacity: 1;
    transition: all 0.5s;
}

.recommendation-form-dropdowns-container-hide {
    display: none;
}

.recommendation-form-submit-btn {
    background-color: #333!important;
    width: fit-content;
    margin-left: auto;
    border-radius: 5px !important;
}

.recommendation-form-show-advenced-options {
    font-weight: 700;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    margin-left: auto;
}