/* SynergyTalentHrm */
.synergy-talent-hrm-container {
    font-family: 'Mulish';
    width: 100%;
    margin-top: 100px;
}

.synergy-talent-hrm-container
.synergy-talent-hrm-description {
    background-color: #262626;
    color: #FFF;
    display: flex;
    padding: 24px 36px;
    border-radius: 24px 24px 0 0;
}

.synergy-talent-hrm-container
.synergy-talent-hrm-description p {
    margin-left: 23px;
    font-weight: 300;
}

.synergy-talent-hrm-container
.synergy-talent-hrm-title {
    font-size: "20px";
    font-weight: 300;
    line-height: 24px;
    margin: 15px 0;
}

.synergy-talent-hrm-container
.synergy-talent-hrm-title span {
    font-weight: 400;
}

.synergy-talent-hrm-container 
.talent-report-synergy-table {
    border-collapse: collapse;
    border: none;
    width: 100%;
}

.synergy-talent-hrm-container 
.talent-report-synergy-table td {
    border: 1px solid black;
    padding: 11px 0;
    padding-left: 12px;
}

.synergy-talent-hrm-container 
.talent-report-synergy-table tr td:first-child {
    border-left: none;
}

.synergy-talent-hrm-container 
.talent-report-synergy-table tr td:last-child {
    border-right: none;
}
.synergy-talent-hrm-container 
.talent-report-synergy-table td div {
    display: flex;
}

.synergy-talent-hrm-container 
.talent-report-synergy-table td div img {
    margin-right: 6px;
}

.synergy-talent-hrm-container 
.talent-report-synergy-table 
.table-cell-content span {
    font-weight: 300;
}

/* Talent Needs */
.talent-needs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 100px;
    font-family: 'Mulish';
}

.talent-needs
.talents-needs-header{
    font-size: 16px;
    text-align: center;
    color: #F8F3EE;
    /* font-weight: 300; */
}

.talent-needs 
.talent-needs-do-section {
    width: 50%;
    padding-right: 10px;
}

.talent-needs 
.talent-needs-dont-do-section {
    width: 50%;
    padding-left: 10px;
}

.talent-needs
.talents-needs-list {
    margin-top:  29px;
}

.talent-needs
.talents-needs-list
.talents-needs-list-single {
    display: flex;
    font-size: 18px;
    margin-bottom: 14px;
    max-width: 100%;
}

.talent-needs
.talents-needs-list
.talents-needs-list-single img {
    margin-right: 10px;
}

.talent-needs
.talents-needs-list
.talents-needs-list-single span {
    font-weight: 300;
}