
.cv-and-qualifiqation-container {
    display: flex;
    flex-direction: column;
}

.cv-and-qualifiqation-pdf-container {
    max-width: 640px;
    margin: auto;
}

.cv-and-qualifiqation-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cv-and-qualifiqation-cv-analyzes-container {
    display: flex;
    flex-direction: column;
    width: 320px;

}

.cv-and-qualifiqation-cv-analyzes-container > div {
    margin-bottom: 14px;
}

.fake-cv-placeholder {
    width: 760px;
    height: 900px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cv-and-qualifiqation-header {
    font-size: var(--report-big-font-size);
    line-height: 16px;
    border-top: 1px solid var(--report-secondary-color);
    border-bottom: 1px solid var(--report-secondary-color);
    padding: 14px 0;
    width: 100%;
}

.cv-and-qualifiqation-insight-single {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
}

.cv-and-qualifiqation-insight-single 
.cv-and-qualifiqation-insights-value {
    font-size: 22px;
    color: var(--report-secondary-color);
    font-weight: var(--report-regular-font-weight);
    margin-right: 18px;
}

.cv-and-qualifiqation-insight-single 
.cv-and-qualifiqation-insights-label {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-regular-font-weight);
}

.cv-and-qualifiqation-cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.cv-and-qualifiqation-card-single {
    padding: 5px 16px;
    border: 1px solid var(--report-primary-color);
    font-size: var( --report-small-font-size);
    color: var(--report-primary-color);
    font-weight: var(--report-regular-font-weight);
    line-height: 16px;
    border-radius: 50px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.cv-and-qualifiqation-cta-tips-button { 
    width: 100%;
    background-color: var(--report-secondary-color);
    padding: 8px 30px;
    align-self: center;
    width: fit-content;
    border-radius: 50px;
    color: #fff;
    font-size: var(--report-regular-font-size);
    font-weight: var(--report-bold-font-weight);
    margin-top: 20px;
}

@media only screen and (min-width: 1367) { 

    .cv-and-qualifiqation-cv-analyzes-container  {

    }

    .cv-and-qualifiqation-pdf-container {
        max-width: 840px;
    }
}