
:root {
  --app-color-black: #333333;
  --app-color-white: #ffffff;
}

// Tailwind
@tailwind base;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600 underline;
  }
  // ol {
  //   list-style: auto;
  //   margin: inherit;
  //   padding: revert;
  // }
  // ul {
  //   list-style: auto;
  //   margin: inherit;
  //   padding: revert;
  // }
}

@tailwind components;
@tailwind utilities;

// Base
html,
body,
#root,
pre {
  white-space: initial;
  overflow-wrap: break-word;
}
ul:not([role='menu']) {
  list-style: auto !important;
  margin: inherit !important;
  padding: revert !important;
}
ol {
  list-style: auto !important;
  margin: inherit !important;
  padding: revert !important;
}
figure {
  width: auto !important;
}

.table-items-menu ul {
  padding: 0!important;
}


.custom-list-style {
  padding: 0 !important;
  list-style: none !important;
}

.custom-list-no-padding {
  padding: 0!important;

}

.app {
  @apply h-full;
  // @apply overflow-y-auto;
}

// Selected text
::selection {
  color: white;
  background: black;
}

@page {
  size: A4 portrait;
  margin: 0;
}

.Toastify__toast-container {
  width: 380px;
}

.app-toast {
  background-color: var(--app-color-black);
  color: var(--app-color-white);
  width: fit-content;
  padding: 4px 16px;
  border-radius: 50px;
  min-height: 50px;
  font-family: 'Mulish';
  font-weight: 300;
  margin: auto;
  margin-bottom: 10px;
}

.app-toast 
.Toastify__toast-icon svg {
  fill: #fff;
}


@media print {
  * {
    overflow: visible !important;
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
  // .reviewChart {
  //   break-after: always;
  //   break-inside: avoid;
  // }
  // .about-talent {
  //   break-inside: avoid;
  //   break-after: always;
  // }
  // .qstion {
  //   break-inside: avoid;
  // }
  .communication-style-sections-for-print {
    page-break-inside: avoid !important;
    padding-top: 20px;
  }
  .talent-report-qustions-answers-section {
    break-before: always;
    page-break-inside: avoid !important;
    padding-top: 20px;
  }
  .report-category {
    page-break-inside: avoid !important;
    padding-top: 20px;
  }
  .communication-style {
    margin-top: 0px !important;
  }
  .comprofile-main {
    width: 150px !important;
  }
  // .talent-needs {
  //   break-before: always;
  //   break-inside: avoid;
  //   padding-top: 20px;
  // }
  .no-print,
  .no-print * {
    display: none !important;
  }
  #no-print {
    display: none !important;
  }
  .talent-report-communication-style-workstyle-description { 
    position: absolute;
    left: -1000px;
  }
  .report-pdf {
    width: 100% !important;
    max-width: none !important;
    top:0 !important;
    position: absolute;
  }
  .review-ihghlights-section-seperate-line {
    margin: 0 15px;
  }
  .react-pdf__Page__svg {
    width: auto !important;
    height: 100% !important;
    margin-right: auto !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    margin-right: auto !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  // .react-pdf__Page__textContent {
  //   margin-top: 0 !important;
  // }
  .react-pdf__Page__svg svg {
    width: 100%;
    height: 100%;
    display: block;
    background-color: white;
}
.cv-text {
  display: block !important;
}
img {
  max-width: 100% !important;
  height: auto;
}
.talent-report-communication-style-workstyle {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
}
.talent-report-communication-style-icon {
    display: flex;
    align-items: center;
    background-color: #5a7bff;
    padding: 12px 12px;
    width: fit-content;
    border-radius: 36px;
    color: white;
    margin-top: 20px;
}
.talent-report-communication-style-icon-read-more {
  display: block !important;
  margin-top: 20px;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.talent-report-communication-style-workstyle-description-main-for-print {
  display: block !important;
  width: 28%;
  margin-left: 16%;
}
.talent-report-communication-style-workstyle-for-print {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.combined-comprofile-for-print {
  display: block !important;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
}

.cv-text {
  display: none;
}

.react-pdf__Page__svg {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.react-pdf__Page__canvas {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.react-pdf__Page__textContent {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  top: 46.5% !important;
  display: none !important;
  // width: auto !important;
  // height: auto !important;
}
.breadcrumbs {
  list-style: none !important;
  margin-bottom: 0.75rem !important;
  display: flex;
  padding: 0 !important;
}
.sideMenu {
  margin-top: 2.5rem;
  ul {
    list-style: none !important;
    padding: 0 !important;
  }
}

.talent-report-communication-style-icon-read-more {
  display: none;
}

.talent-report-communication-style-workstyle-description-main-for-print {
  display: none;
}
.combined-comprofile-for-print {
  display: none;
}
.comprofile-main {
  width: 220px;
}


// Pages
@import 'pages/login.scss';
@import 'pages/reviewProcess.scss';

// Menu
@import 'layouts/menu/menu.scss';

// Components
@import 'components/lnrd.scss';
@import 'components/wizard.scss';
// @import "components/jsonforms.scss";
@import 'components/animate.scss';
