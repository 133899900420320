.custom-search-input { 
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 290px;
}

.custom-search-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
    min-width: 180px;
    height: 45px;
}

.custom-search-button {
    background-color: #333;
    height: 45px;
    border-radius: 12px;
}

.custom-search-input:focus, .custom-search-input:focus, .custom-search-input:focus{
    outline: none;
}

.search-questions-dialog-filters-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 26px;
}

.search-questions-dialog-filters-section > * {
    margin-right: 12px;
}