.insights-and-ai {
  display: flex;
  justify-content: space-between;
}

.our-insights-container {
  display: flex;
  flex-direction: column;
}

.our-insights {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.our-insights > * {
  margin-right: 10px;
}

.our-insights-arrow-icon {
  font-size: 12px !important;
}

.our-insights-insight {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--report-primary-color);
  border-radius: 20px;
  padding: 4px 16px 4px 8px;
  cursor: pointer;
}

.our-insights-insight-value {
  display: flex;
  flex-direction: row;
}

.our-insights-insight-unverified {
  font-size: var(--report-regular-font-size);
  margin-left: 4px;
  color: var(--report-secondary-color);
}

.our-insights-insight-verified {
  font-size: var(--report-regular-font-size);
  margin-left: 4px;
  color: var(--report-success-color);
  text-decoration: none;
}

.our-insights-insight-verified svg {
  width: 12px;
  height: 12px;
}

.our-insights-channel-description {
  padding: 6px;
  color: var(--report-primary-color);
  line-height: 20px;
}

.our-insights-insight span {
  font-size: var(--report-regular-font-size);
  margin-left: 4px;
}

.our-insights-advice {
  display: flex;
  flex-direction: row;
  background-color: var(--report-secondary-color);
  border: 1px solid var(--report-secondary-color);
  border-radius: 50px;
  padding: 6px 20px;
  margin-right: 15px;
  width: fit-content;
  align-items: center;
  margin-top: 24px;
  padding-left: 12px;
}

.our-insights-advice img {
  margin-right: 8px;
}

.our-insights-advice span {
  font-size: var(--report-big-font-size);
  font-weight: var(--report-light-font-weight);
  color: #fff;
}

.our-insights-advice-span {
  text-decoration: underline;
  cursor: pointer;
}
