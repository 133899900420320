.login-page {
    width: 100%;
    min-height: 100vh;
    background-color: #F8F3EE;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-page-container {
    padding: 40px 0;
    width: 680px;
}

.login-page-container
.login-page-logo {
    width: 126px;
    height: 11px;
    margin: auto;
}

.login-page-container
.login-page-icon {
    margin: 42px auto;
}

.login-page-container
.login-page-header {
    font-size: 35px;
    line-height: 37px;
    font-weight: 700;
    color: #000;
    text-align: center;
}

.login-page-container
.login-page-description {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    color: #000;
    margin: 16px auto 38px auto;
    text-align: center;
}

.login-page-container
.login-page-sentence {
    font-size: 12px;
    font-weight: 300;
    color: #000;
    text-align: center;
}

.login-page-container
.login-page-buttons {
    margin: 15px auto 60px auto;
    width: 300px;
}

.login-page-container
.login-page-buttons button {
    border-radius: 24px;
}

.login-page-container
.login-page-footer {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    text-decoration: none;
    color: #000;
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
}

.login-page-container
.login-page-footer a {
    text-decoration: none;
    color: #000;
}

@media only screen and (max-width: 680px) {
    .login-page-container {
        width: 100%;
        padding: 40px 30px;
    }

    .custom-loading-size {
        width: 60% !important;
    }
    .custom-loading-size img { 
        width: 100% !important;
    }

    .login-page-container
    .login-page-header {
        font-size: 30px;
        line-height: 37px;
    }

    .login-page-container
    .login-page-description {
        font-size: 14px;
        margin: 16px auto 16px auto;
    }

    .login-page-container
    .login-page-description br{
        display: none;
    }

    .login-page-container
    .login-page-buttons {
        margin: 6px auto 60px auto;
    }

    .login-page-container
    .login-page-sentence {
        font-size: 10px;
    }
}