.skills-step-container {
    width: 600px;
}

.skills-step-container > *{
    margin-bottom: 30px;
}

.skills-step-cv-keywords-container {
    margin-top: 10px;
    width: 550px;
}

.skills-input-wrapper {
    border: none !important;
    padding: 0 !important;
    padding-bottom: 10px !important;
}

.skills-suggestion-card-custom-add-new {
    margin: 0;
    margin-top: 4px;
}

.skills-and-traits-add-skill-input input {
    border: 1px solid #333 !important;
    height: 32px;
    align-self: center;
    border-radius: 50px;
    padding: 4px 10px !important;
}