.report-header-container {
    display: flex;
    flex-direction: row;
    padding: 40px 0;
}

.report-header-container img {
    margin-right: 10px;
}

.report-header-container h2 {
    font-size: var(--report-big-font-size);
    text-transform: uppercase;
    letter-spacing: 5px;
}