.building-blocks-checkboxes {
    display: flex;
    flex-direction: column;
}

.building-blocks-step-container {
    padding: 28px 34px;
    background-color: #5A61FF;
    max-width: 600px;
    border-radius: 5px;
    animation: card-fade-animation 0.5s ease-in-out;
}

.building-blocks-checkbox {
    color: #fff;
}

.building-blocks-step-header { 
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 12px;
}

.building-blocks-step-sentence { 
    font-size: 14px;
    color: #fff;
    margin-bottom: 25px;
}

.building-blocks-checkbox-seperate-line {
    border-top: 1px dashed #fff;
    width: 100%;
    margin: 0px 0 10px;
}

.building-blocks-step-sim-btn-container{ 
    margin-top: 34px;
    display: flex;
    flex-flow: row;
    align-items: center;
}

.building-blocks-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.building-blocks-choose-default-question {
    display: flex;
    flex-direction: row;
    font-size: 11px !important;
    width: 304px;
    gap: 16px;
    height: 32px;
    color: #FFF;
    align-items: center;
    background-color: #fff5;
    padding: 4px 12px;
    border-radius: 5px;
}

.building-blocks-choose-default-question
p {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    line-height: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.building-blocks-step-sim-btn {
    padding: 12px 48px;
    background-color: #fff;
    color: #5A61FF;
    width: fit-content;
    border-radius: 5px;
    text-decoration: none;
}

.building-blocks-step-sim-icon {
    margin-left: 20px;
    font-size: 28px; 
    color: #fff;
    cursor: pointer;
}

.building-blocks-step-sim-icon-disabled {
    color: #fff5 !important;
    cursor: not-allowed;
    pointer-events: none;
}

.building-blocks-step-sim-icon-small {
    font-size: 16px !important; 
    color: #fff;
    cursor: pointer;
}

.building-blocks-step-customize-button {
    cursor: pointer;
    margin-top: 18px;
    font-size: 12px;
    color: #fff;
}

.building-blocks-step-cancel-btn {
    padding: 10px;
    margin-left: 14px;
    color: #fff
}

@keyframes card-fade-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* WhatsNext */

.whats-next-component {
    display: flex;
    flex-direction: column;
}

.whats-next-title {
    font-size: 16px;
    font-weight: 700;
    margin-top: 54px;
}

.whats-next-cards {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.whats-next-card {
    background-color: #333;
    color: #fff;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-bottom: 12px;
    padding: 6px 12px;
    border-radius: 5px;
    width: 360px;
    align-items: center;
}

.whats-next-card {
    font-size: 14px;
    font-weight: 300;
}

.whats-next-card svg {
    margin-right: 16px;
}

.choose-default-question-modal {
    width: 600px;
    height: 400px;
    padding: 12px;
    font-family: 'Mulish';
    position: relative;
}

.choose-default-question-description {
    font-size: 12px;
    font-weight: 300;
}

.choose-default-question-dropdown-container {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.choose-default-question-dropdown {
    width: 300px !important;
}

.choose-default-question-add-new-btn {
    font-size: 12px;
    color: #6E74FF;
    height: 35px;
    align-self: end;
}

.choose-default-question-done-btn {
    border-radius: 5px !important;
    position: absolute;
    bottom: 20px;
}

.choose-default-question-loading-container {
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}