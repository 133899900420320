.notification-center-single {
    width: 100%;
    padding: 10px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.notification-center-single-content {
    display: flex;
    flex-direction: column;
}

.notification-center-single-title {
    padding: 5px 0;
    font-size: 14px;
}

.notification-center-single-title-event {
    font-weight: 400;
    line-height: 18px;
}

.notification-center-single-title-value {
    font-weight: 400;
}

.notification-center-single-title-company-name {
    font-family: "Mulish";
    display: block;
    font-weight: 400;
    font-size: 12px;
    /* font-style: italic; */
    line-height: 18px;
    /* margin-bottom: 5px; */
}

.notification-center-single-tags {
    font-size: 12px;
    font-weight: 200;
}

.notification-center-single-tags 
.notification-center-single-tag {
    /* padding: 5px 0; */
}

.notification-center-single-buttons {
    padding: 0px 1px;
    color: blueviolet;
}

.notification-center-single-buttons button {
    font-size: 12px;
    padding: 5px 0;
    font-weight: 200;
}

.notification-center-single-buttons button:last-child {
    margin-left: 10px;
}

.notification-center-single-seperate-line {
    height: 0.5px;
    background-color: #7777772b;
    width: 90%;
    margin: auto;
}

.notification-center-single-date {
    width: 28%;
    text-align: end
}

.notification-center-single-date span {
    color: #A0A0A0;
    font-size: 12px;
    font-weight: 400;
}

.unread-notification-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #10C041;
    display: inline-flex;
    margin-left: 8px;
    margin-bottom: 2px;
}