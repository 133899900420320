
.candidate-video-question-modal {
    padding: 14px 18px;
    display: flex;
    flex-direction: column;
    font-family: var(--report-font-family);
    min-width: 658px;
    min-height: 300px;
}

.candidate-video-question-modal-header {
    font-size: 20px;
    font-weight: var(--report-regular-font-weight);
}

.candidate-video-question-modal-question {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-lightest-font-weight);
    margin-top: 14px;
    display: flex;
    flex-direction: column;
}

.candidate-video-question-modal-question > span {
    font-weight: var(--report-bold-font-weight);
}

.candidate-video-question-modal-question-container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 4px;
}

.candidate-video-question-modal-video {
    margin-top: 24px;
    width: 100%;
}

.candidate-video-question-modal-video video {
    border-radius: 12px;
    height: 350px;
    width: 100%;
}

.candidate-video-question-modal-video-loading {
    height: 350px;
    width: 100%;
    border-radius: 12px;
    background-color: var(--report-tertiary-color);
}

.candidate-video-question-modal-tips {
    margin-top: 18px;
}

.candidate-video-question-modal-tips span{
    font-size: var(--report-regular-font-size);
    color: var(--report-secondary-color);
}

.candidate-video-question-rank-section {
    margin-top: 32px;
}