.talent-report-message{
    width: fit-content;
}

.talent-report-message-text {
    padding: 18px;
    width: fit-content;
    border-radius: 5px;
    font-size: 20px;
}

.message-traingle {
    width: 20px;
    margin: auto;
    margin-top: -13px;
    height: 38px;
  }