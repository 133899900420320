.integrate-editor {
    width: 460px;
    height: 300px;
    font-family: 'Mulish';
    padding: 0 40px;
    display: flex;
    flex-direction: column;
}

.integrate-editor-headers {
    padding: 44px 0px 22px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.integrate-editor-headers h2 {
    font-size: 30px;
    font-weight: 300;
}

.integrate-editor-how-to-link {
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    text-align: end;
    margin-top: 8px;
}

.integrate-editor-done-button {
    background-color: #333 !important;
    width: fit-content;
    align-self: end;
    margin-top: 36px;
    border-radius: 5px;
}