.wiz-page {
  --wiz-text-lg: 30px;
  --wiz-text-md: 18px;
  --wiz-text-sm: 14px;
  --wiz-text-xs: 10px;

  --wiz-text-color: #333333;
  --wiz-text-edit-color: #999999;

  --wiz-btn-bg-light: #EDEDED;
  --wiz-btn-bg: #000000;

  --wiz-text-btn-sm: 14px;
  --wiz-text-btn-md: 16px;

  --wiz-btn-text-w: #ffffff;
  --wiz-btn-text-b: #000000;

  --wiz-radius: 5px;

  --wiz-font-bold: 700;
  --wiz-font-extralight: 200;
  --wiz-font-normal: normal;

  --wix-border-color-light: #C4C4C4;
  max-width: 600px;


  &.wiz-valid-page {} // FIXME: remove unused class

  &.wiz-invalid-page {} // FIXME: remove unused class

  .wiz-edit-text {
    color: var(--wiz-text-edit-color);
  }

  *[class^="wizard-form"] {
    @extend .wiz-edit-text;
  }

  .wiz-text-lg {
    font-size: var(--wiz-text-lg);
    font-weight: 200;
    color: var(--wiz-text-color);
  }

  .wiz-text-md {
    color: var(--wiz-text-color);
    font-size: var(--wiz-text-md);
  }

  .wiz-text-sm {
    font-size: var(--wiz-text-sm);
    font-weight: normal;
    color: var(--wiz-text-color);
  }

  .wiz-text-xs {
    font-size: var(--wiz-text-xs);
    font-weight: normal;
    color: var(--wiz-text-color);
  }

  .wiz-text-bold {
    font-weight: var(--wiz-font-bold);
  }

  .wiz-text-normal {
    font-weight: var(--wiz-font-normal);
  }

  .wiz-text-light {
    font-weight: var(--wix-border-color-light);
  }

  .wiz-prev-btn {
    background-color: var(--wiz-btn-bg-light);
    color: var(--wiz-btn-text-b);
    border-radius: 5px 0px 0px 5px;
    border-radius: var(--wiz-radius);

    &.has-next {
      border-radius: var(--wiz-radius) 0 0 var(--wiz-radius);
    }

    margin-right: 2px;
    width: 38px;
    height: 35px;
  }

  .wiz-next-btn {
    background-color: var(--wiz-btn-bg);
    color: var(--wiz-btn-text-w);
    height: 35px;
    min-width: 74px;
    font-size: var(--wiz-text-btn-md);
    border-radius: var(--wiz-radius);

    &.has-prev {
      border-radius: 0 var(--wiz-radius) var(--wiz-radius) 0;
    }

  }

  .wiz-finish-btn {
    background-color: var(--wiz-btn-bg);
    color: var(--wiz-btn-text-w);
    height: 35px;
    min-width: 74px;
    font-size: var(--wiz-text-btn-md);
    border-radius: var(--wiz-radius);
  }

  .wiz-add-field {
    font-size: var(--wiz-text-sm);
  }

  .wiz-trash {
    color: var(--wiz-text-color);
  }

  .wiz-progress {

    .wiz-progress-text {
      @extend .wiz-text-xs;
      margin-bottom: 2px;
      color: var(--wiz-text-color);
    }
  }
}


.wiz-preview-page {
  @extend .wiz-page;

  .wiz-preview-title {
    @extend .wiz-text-lg;
  }

  .wiz-preview-content {
    width: 500px;
    display: flex;
    flex-direction: column;

    .wiz-preview-subtitle {
      @extend .wiz-text-sm;
    }

    .wiz-preview-link {
      flex-grow: 1;
    }
  }

  .wiz-preview-info {
    border-radius: var(--wiz-radius);
    width: 277px;
    height: 315px;
    box-sizing: border-box;
    padding: 48px;
    border: 1px dashed var(--wix-border-color-light);
  }
}