.custom-chat-btn-company-context {
    font-family: 'Mulish';
    height: 22px;
    font-weight: 400;
    font-size: 12px;
    color: #000;
    background-color: #fff;
    padding: 0 8px;
    border-radius: 5px;
}

.radius-chat-btn {
    border-radius: 50%;
    background: #fff;
    width: 38px;
    padding: 8px !important;
    justify-content: center;
    box-shadow: 2px 4px 7px rgb(0 0 0 / 5%);
}

.positions-chat-container {
    margin-top: 40px;
}