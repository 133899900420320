.day-in-a-life-row-container {
    display: flex;
    flex-direction: row;
}

.day-in-a-life-wrapper :first {
    margin-top: 0;
}

.day-in-a-life-wrapper {
    margin-top: 10px;
    width: unset;
    display: flex;
    flex-direction: column;
}