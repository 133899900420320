.wizard-step-template-title {
    font-weight: 200;
    color: var(--title-color)
}


.wizard-step-template-description { 
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,.6980392156862745);
    margin: 16px 0;
}

.wizard-step-template-content {
    width: 100%;
}

.wizard-step-template-content-container {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
}

.wizard-step-template-preview-section {
    width: 40%;
    min-width: 40%;
}