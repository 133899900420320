// Override variables
$sidebar-bg-color: #f8f8f8;
$icon-bg-color: transparent;

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 0px !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: #f8f8f8 !important;
}

.pro-sidebar .pro-menu a {
  color: black !important;
}

.pro-sidebar {
  color: black !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  color: black !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item::before {
  display: none !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .react-slidedown.pro-inner-list-item ul li.pro-menu-item {
  padding-left: 32px !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: inherit !important;
}

@import '~react-pro-sidebar/dist/scss/styles.scss';
