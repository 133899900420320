.array-control-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.array-control-inputs-group {
    display: flex;
    flex-direction: row;
    width: 95%;
}

.array-control-inputs {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.array-control-inputs-group > * {
    margin-right: 5px;
    width: 33%;
}

.array-control-inputs-group:last-child {
    margin-right: 0 !important;
}

.array-control-header-add-button {
    cursor: pointer;
    font-size: 12px;
    font-family: 'Mulish';
    margin-bottom: 30px;
    color: #FF8E5A;
}

.array-control-delete-container {
    min-height: 100%;
    display: flex;
    align-items: center;
}