.review-qualification-skills-overall {
    display: flex;
    flex-flow: row;
}

.review-qualification-skills-overall-circle {
    text-align: center;
}

.review-qualification-skills-overall-circle:first-child {
    margin-right: 85px;
}

.review-qualification-skills-overall-circle h3{
    color: #FF8E5A;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 31px;
}

.review-qualification-skills-overall-circle .review-qualification-skills-span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #262626;
}