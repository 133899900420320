
.cp-instructions-wrap {
    display: flex;
    align-items: center;
}

.cp-instructions-wrap p {
    margin-bottom: 0px;
}

.cp-instructions-wrap p:last-of-type {
    margin-top: 5px;
}

.cp-instructions {
    background: rgba(243, 173, 129, 0.12);
    border-radius: 5px;
    padding: 20px 30px;
    width: 710px;
    color: #000;
    font-size: 12px;
    font-family: "Roboto";
    font-style: normal;
}

.cp-instructions-headline {
    font-weight: 700;
    margin-bottom: 0px;
}

@media (max-width:1346) {
    .cp-instructions {
        width: "auto"
    }
}
