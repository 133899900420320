.integrate-row {
    display: flex;
    flex-direction: row;
    height: 114px;
    align-items: center;
    width: 100%;
    font-family: 'Mulish';
    border-bottom: 1px solid #EDEDED;
}

.integrate-row:last-child {
    border-bottom: none;
}

.integrate-row
.integrate-name-logo {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 22px;
}

.integrate-row
.integrate-name-logo span {
    font-size: 16px;
    font-weight: 400;
}

.integrate-row
.integrate-description {
    width: 60%;
    font-size: 14px;
    font-weight: 400;
}

.integrate-row
.integrate-button {
    width: 20%;
    justify-content: center;
}

.integrate-button-active {
    background-color: #333 !important;
    color: #fff;
}

.integrate-button-btn {
    padding: 20px 26px !important;
    border-radius: 14px;
    background-color: #EDEDED;
}