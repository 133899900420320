.candidate-report-navigation-bar {
    width: 70px;
    background-color: #262626;
    position: sticky;
    top: 0;
    left: 0;
}

.candidate-report-navigation-bar-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    border-bottom: 1px solid #FBF6F650;
    cursor: pointer;
    transition: all 1s ease-in-out;
}

.candidate-report-navigation-bar-item-a {
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    border-bottom: 1px solid #FBF6F650;
    cursor: pointer;
    transition: all 1s ease-in-out;
    text-decoration: none;
}

.candidate-report-navigation-bar-item
.candidate-report-navigation-bar-item-label {
    font-size: var(--report-smaller-font-size);
    color: #fff;
    text-align: center;
    margin-top: 5px;
}

.candidate-report-navigation-bar-item-label-ats {
    font-size: var(--report-smaller-font-size);
    color: #fff;
    text-align: center;
    margin-top: 5px;
    text-decoration: none;
}

.navigation-bar-item-active {
    background-color: var(--report-secondary-color);
}

.candidate-report-navigation-bar-item-traingle {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid var(--report-quinary-color);
    transform: rotate(-90deg);
    position: absolute;
    right: -4px;
    display: none;
    transition: all 1s ease-in-out;
}

.navigation-bar-show-traingle {
    display: block;
}