
@keyframes upload-anim  {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
}

.upload-anim {
  animation-duration: 0.5s;
  animation-name: upload-anim;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.image-upload-container {
  position: relative;
  width: 102px;
  height: 102px;
}
.image-upload-container .image-upload-wrapper {
  font-style: normal;
  height: 100%;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer;
  color: #333333;
  text-align: center;
  padding: 20px;
  border: 1px dashed #999999;
  box-sizing: border-box;
  border-radius: 5px;
}

.image-upload-container .hide {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.image-upload-preview {
  position: absolute;
  background-color: white;
  width: 102px;
  height: 102px;
  top: 0;
  border-radius: 5px;
}

.image-upload-container img {
  object-fit: cover;
}

.image-upload-actions-wrapper {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-upload-actions-wrapper .image-upload-icon-wrapper {
  background-color: rgba(255, 255, 255, 0.7);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  border-radius: 5px;
}
