.position-wizard-main {
    max-width: 600px;
    min-height: 540px;
    position: relative
}

.wizard-suggestion-card-custom-step {
    margin-bottom: 0 !important;
    padding: 4px 10px !important;
    background-color: #333333 !important;
}

.wizard-new-template-warning-modal {
    max-width: 500px;
}