.leaders-tab-data-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.leaders-tab-data-text-fields-container { 
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
    width: 100%;
    margin-right: 8px;
    margin-bottom: 20px;
}

.leaders-tab-data-text-fields-container :last-child {
    margin-bottom: 0;
}

.leaders-tab-data-text-fields-name-title { 
    display: flex;
    flex-flow: row;
    width: 100%;
}

.leaders-tab-data-text-fields-name-title > * {
    margin-right: 8px;
}


.leaders-tab-data-text-fields-name-title :last-child {
    margin-right: 0 !important;
}


.leaders-tab-data-image-upload {
    height: 45px;
    width: 120px;
    /* border-radius: 12px; */
}

.leaders-tab-data-image-upload label {
    padding: 0 !important;
    border-radius: 12px !important;
}

.leaders-tab-data-image-upload img {
    width: 120px;
    height: 45px;
    background-size: cover;
    border-radius: 12px !important;
}