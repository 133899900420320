.report-header {
  display: flex;
  align-items: center;
}

.report-header-cv-download {
  display: flex;
  width: 210px;
}

.report-header-cv-download img {
  width: 15px;
  height: 15px;
}

.report-header-cv-download span {
  font-size: 12px;
  font-weight: 600;
  margin-left: 11px;
  text-decoration: underline;
  cursor: pointer;
}

.report-header-info-link-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 50px;
}

.report-header-info-link-section div {
  display: flex;
  flex-flow: row;
}

.report-header-personal-info {
  padding: 0 20px;
}

.report-header-personal-info span {
  font-size: 12px;
}

.report-header-personal-info:not(:first-child) div {
  width: 1.8px;
  height: 13px;
  background-color: #262626;
  align-self: center;
  margin-right: 7px;
}

.report-header-personal-info img {
  margin-right: 5px;
  width: 18px;
}
