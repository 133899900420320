.custom-select-style {
    width: fit-content;
}
.custom-select-style .css-e2sey-control {
    border: 2px solid black;
}
.custom-select-style .css-cx7yxe-control {
    border: 2px solid black !important;
}
.custom-select-style .css-e2sey-control:hover {
    border: 2px solid black;
}

.wizard-create-new-position {
    font-family: 'Mulish';
    display: flex;
    flex-direction: row;
}

.wizard-create-new-position-names-suggestions-container {
    margin-top: 32px;
}
.wizard-create-new-position-names-suggestions {
    display: flex;
    flex-flow: wrap;
}
.wizard-create-new-position-names-suggestions-text {
    font-size: 12px;
}

.wizard-create-new-position-other-titles-container {
    margin-top: 12px;
    display: flex;
    flex-flow: wrap;
}

.wizard-create-new-position-hovered-suggestion-container {
    padding-top: 12px;
    max-height: 450px;
    overflow-y: auto;
}

.create-new-position-position-option {
    display: flex;
    flex-direction: column;
}

.create-new-position-position-option
.create-new-position-position-option-title {
    font-size: 14px;
    width: 100%;
}

.create-new-position-position-option
.create-new-position-position-option-description {
    font-size: 12px;
    margin-top: 8px;
    overflow: hidden;
    display: -webkit-box;
    font-weight: 200;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.create-new-position-position-option
.create-new-position-position-option-skills {
    font-size: 10px;
    margin-top: 8px;
    font-weight: 200;
    overflow: hidden;
}

.create-new-position-position-option
.create-new-position-position-option-skills span {
    margin-right: 8px;
}

.wizard-create-new-position-searchbox-container {
    display: flex;
    flex-direction: column;
}

.start-from-scratch-link {
    text-decoration: underline;
    cursor: pointer;
}

/* CreatePositionFromScratchModal */

.create-position-from-scratch-modal {
    position: relative;
    min-height: -webkit-fill-available;
}

.create-position-from-scratch-container {
    padding: 20px 30px;
    min-height: 100%;
    display: flex;
    flex-flow: column;
}

.create-position-from-scratch-modal-title {
    font-size: 16px;
    font-weight: 400;
}

.create-position-from-scratch-modal-description {
    font-size: 14px;
    margin-top: 8px;
    font-weight: 400;
}

.create-position-from-scratch-modal-description span {
    color: #FF6E30;
}

.create-position-from-scratch-search-container {
    display: flex;
    flex-direction: row;
}

.create-position-from-scratch-search-container input {
    width: 220px;
    margin-right: 10px;
}

.create-position-from-scratch-modal-search-button {
    font-size: 12px !important;
    border-radius: 12px !important;
}

.create-position-from-scratch-modal-search-button:disabled {
    background-color: #e0e0e0 !important;
    cursor: not-allowed !important;
}

.create-position-from-scratch-names-suggestions-span {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
}

.create-position-from-scratch-names-suggestions {
    display: flex;
    flex-flow: wrap;
    height: 150px;
    max-height: 150px;
    overflow-y: scroll;
    border: 1px solid #333333;
    border-radius: 12px;
    padding: 10px;
}

.create-position-from-scratch-submit-button {
    background: #FF6E30;
    color: #fff;
    padding: 8px 14px;
    font-size: 14px;
    margin-top: auto;
    border-radius: 5px;
    display: flex;
    flex-flow: row;
    align-items: center;
    width: fit-content;
}
.create-position-from-scratch-submit-button:disabled {
    opacity: 0.6;
}

.create-position-from-scratch-submit-button svg {
    font-size: 18px;
    margin-left: 8px;
    align-self: end;
}

.create-position-from-scratch-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #0003;
}

/* PositionPreview */
.position-preview-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-family: 'Mulish';
}

.position-preview-title {
    font-size: 16px;
    font-weight: 600;
}

.position-preview-description {
    font-size: 14px;
    margin-top: 8px;
    font-weight: 200;
}

.position-preview-items-container {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.position-preview-items-label {
    font-size: 14px;
    font-weight: 400;
}

.position-preview-items-items {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
}

.position-preview-items-item:hover {
    background-color: #000;
    color: #fff;
    border: 1px solid #333333;
}

.custom-text-input-fresh-start {
    border-radius: 50px !important;
    margin-top: 20px;
    width: 290px !important;
    height: 40px;
    padding: 8px 20px;
    font-size: 14px;
}