.report-modal-header {
    position: relative;
}

.report-modal-header-title {
    font-size: 20px;
    margin-top: 16px;
    margin-left: 12px;
}

.report-modal-header-close-icon {
    position: absolute;
    right: 12px;
    top: 16px;
    cursor: pointer;
}