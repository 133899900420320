.correct-incorrect-container {
    display: flex;
    flex-flow: row;
}

.correct-incorrect-container img {
    width: 20px;
    line-height: 20px;
    margin-right: 6px;
}

.correct-incorrect-container-imagepicker {
    position: relative;
    width: fit-content;
}

.correct-incorrect-icon-imagepicker {
    position: absolute;
    top: 0;
    right: 5px;
}

.incorrect-container {
    color: #F06969 !important;
}

.correct-container {
    color: #50B685 !important;
}

.correct-incorrect-icon {
    color: #FFF;
    width: fit-content;
    height: fit-content;
    align-self: center;
    border-radius: 12px;
    width: 16px;
}

.correct-icon {
    background-color: #50B685 !important;
}

.incorrect-icon {
    background-color: #F06969 !important;
}

.correct-answer {
    color: #50B685 !important;
}
.incorrect-answer {
    color: #F06969 !important;
}

.checkbox-list-style {
    list-style: none !important;
    padding: 8px 0px 0px 0px !important;
    margin-top: x;
}

.rating-ranking-answer {
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
}

.rating-ranking-answer
.rating-ranking-answer-section {
    display: flex;
    flex-flow: row;
}

.rating-ranking-answer
.rating-ranking-answer-section:first-child {
    margin-bottom: 8px;
}


.rating-ranking-answer-text {
    text-decoration: underline;
    margin-right: 6px;
}

.ranking-answer-custom {
    display: flex;
    flex-flow: row;
}

.ranking-answer-custom
.ranking-answer-custom-section:first-child {
    margin-right: 10px;
}

.custom-table-rating-answer * {
    font-family: 'Mulish';
}

.custom-table-rating-answer td {
    padding: 10px 40px 10px 0px;
    max-width: 550px;
    line-break: auto;
}

.custom-table-rating-answer th {
    padding-left: 0;
    padding-right: 40px;
}

.matrix-answer-candidates-answer-cell * {
    color: black !important;
}

.matrix-answer-candidates-answer-cell {
    max-width: 180px;
}

.matrix-answer-candidates-answer-cell img {
    align-self: start;
    margin-top: 3px;
}

.matrix-answer-question-cell {
    max-width: 450px !important;
}

.image-picker-image-size {
    width: -webkit-fill-available;
}