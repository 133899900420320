.wizard-custom-text-field {
    padding: 0 12px;
    height: 45px;
    border-radius: 12px;
    font-size: 12px;
}

.wizard-elements-text-field-grey {
    background-color: #EDEDED;
}

.wizard-elements-text-field-white-bordered {
    background-color: #fff;
    border: 1px solid #000;
}

.wizard-custom-text-field:focus {
    outline: none;
}

.wizard-text-field-container {
    display: flex;
    flex-flow: column;
}