.table-enum-filter-section {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 14px;
    max-width: 78%;
}

.table-enum-filter-dropdwon {
    width: fit-content !important;
    border-radius: 5px !important;
    height: 100% !important;
}

.table-enum-filter-selected {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.table-enum-filter-dropdowns {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
}

.custom-filter-chip {
    font-size: 12px !important;
    border: 1px solid #C2C2C2;
    background-color: unset !important;
}