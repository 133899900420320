.cv-custom-page {
    width: 100%;
}

.cv-custom-page canvas {
    width: 100% !important;
    margin: 0!important;
}

.cv-pdf {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}