.progress-bar-container {
  display: none;
}

.progress-bar-container-desktop {
  display: flex;
  flex-direction: column;
  flex: 1/2;
  justify-content: center;
  align-items: flex-start;
}

/* Smartphones (portrait) */
@media only screen and (max-width : 640px) {

  .progress-bar-container {
    display: flex;
    flex-direction: column;
    flex: 1/2;
    justify-content: center;
    align-items: flex-start;
  }

  .progress-bar-container-desktop {
    display: none;
  }

}