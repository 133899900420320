.empty-state-title {
    font-family: 'Mulish';
    font-size: 20px;
    font-weight: 300;
}

.empty-state-image {
    height: 70px;
}

.empty-state-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
}