.content-card { 
    background-color: #fff;
    padding: 12px 14px;
    border-radius: 10px;
    justify-content: space-between;
}


.card-sm {
    flex: 1;
    height: 100px;
    margin-right: 16px;
}

.card-sm:last-of-type {
    margin-right: 0;
}

.card-sm
.content-card-title {
    font-size: 10px;
}

.card-md {
    flex: 1;
    height: 200px;
    margin-right: 16px;
    /* width: min-content; */
}

.card-md:last-of-type {
    margin-right: 0;
}

.card-md
.content-card-title {
    font-size: 12px
}

.card-max {
    width: 100%;
}