.communication-style-v2 
.talent-report-communication-style-headers h2{
    font-size:  var(--report-header-1-font-size);
    color: var(--report-primary-color);
}

.communication-style-v2 
.talent-report-communication-style-headers span {
    font-size: var(--report-small-font-size);
}

.communication-style-v2 
.talent-report-communication-style-workstyle {
    /* margin-top: 36px; */
    display: flex;
}

.communication-style-v2 
.talent-report-communication-style-workstyle h3 {
    font-size: 20px;
    margin-right: 11px;
    font-weight: 400;
    line-height: 52px;
}

.communication-style-v2 
.talent-report-communication-style-workstyle h3 span {
    text-decoration: underline;
    color: #5a7bff;
}

.communication-style-v2 
.talent-report-communication-style-workstyle 
.talent-report-communication-style-icon {
    display: flex;
    align-items: center;
    background-color: #5a7bff;
    padding: 12px 12px;
    width: fit-content;
    border-radius: 36px;
    color: white;
}

.communication-style-v2 
.talent-report-communication-style-workstyle 
.talent-report-communication-style-icon span { 
    font-size: 19px;
    font-weight: 300;
}

.communication-style-v2 
.talent-report-communication-style-workstyle 
.talent-report-communication-style-icon
.talent-report-communication-style-icon-image {
    width: 32px;
}

.communication-style-v2 .talent-report-ask-yourself-message {
    color: #262626;
    font-size: var(--report-header-2-font-size);
}

.communication-style-v2 .talent-report-ask-yourself-message span {
    font-weight: 700;
}

/*
 * Communication style description
 * 
 */
.talent-report-communication-style-workstyle-description {
    display: flex;
    justify-content: flex-start;
    margin-top: 35px;
}

.talent-report-communication-style-workstyle-description-single {
    display: flex;
    height: fit-content;
    font-weight: 300;
    font-size: var(--report-big-font-size);
    margin-bottom: 30px;
}

.talent-report-communication-style-workstyle-description-single img {
    width: 24px;
    margin-right: 20px;
}

.talent-report-communication-style-workstyle-description-single span {
    text-decoration: underline;
    font-weight: 700;
    color: #5a7bff;
}

.talent-report-communication-style-workstyle-description-single-line-v2 {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.talent-report-communication-style-workstyle-description-single-line-v2 div {
    border: 0.6px solid #262626;
    border-radius: 18px;
    width: fit-content;
    padding: 7px 12px;
    margin-right: 8px;
    font-size: 12px;
}

.talent-report-communication-style-ask-sentence-wrapper {
    width: fit-content;
}

.talent-report-communication-style-ask-sentence {
    color: #262626;
    padding: 18px;
    background-color: #D8D8D8;
    width: fit-content;
    border-radius: 5px;
    font-size: 20px;
}
 
.talent-report-communication-style-ask-sentence span {
    font-weight: 700;
}

.talent-report-communication-style-workstyle-description-main {
    width: 22%;
    margin-left: 25%;
}

@media only screen and (max-width: 1367px) {
    .talent-report-communication-style-workstyle-description-main {
        width: 28%;
        margin-left: 16%;
    }
 }