.hiring-brain-header-description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: 'Mulish';
}

.hiring-brain-header-description h1 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
}

.hiring-brain-header-description p {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    white-space: pre-line;
}

.hiring-brain-step-description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: 'Mulish';
    margin-top: 26px;
}

.hiring-brain-step-description h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.hiring-brain-step-description p {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    white-space: pre-line;
}

.hiring-brain-step-description span {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
}