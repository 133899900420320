.report-question-feedback-container {
    font-family: var(--report-font-family);
}

.report-question-feedback-controllers {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.report-question-feedback-controllers > * {
    margin-right: 18px;
}

.question-feedback-controllers-slider-wrapper {
    width: 240px;
}

.question-feedback-controllers-label {
    font-size: var(--report-big-font-size);
}

.question-feedback-controllers-button {
    padding: 10px 58px;
    background-color: var(--report-secondary-color);
    border-radius: 50px;
    font-size: var(--report-regular-font-size);
    color: #fff;
}

.report-question-feedback-instructors-container {
    margin-top: 14px;
    margin-bottom: 16px;
}

.question-feedback-tips-span {
    font-size: var(--report-regular-font-size);
    color: var(--report-secondary-color);
}

.report-question-feedback-instructors {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: var(--report-senary-color);
    padding: 20px;
    border-radius: 12px;
    position: relative;
    max-width: 800px;
}

.report-question-feedback-instructors
.feedback-instructors-traingle {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid var(--report-senary-color);
    transform: rotate(0deg);
    position: absolute;
    top: -10px;
}

.report-question-feedback-instructors h3 {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-bold-font-weight);
    color: var(--report-primary-color)
}

.report-question-feedback-instructors span {
    font-size: var(--report-big-font-size);
    font-weight: var(--report-light-font-weight);
    color: var(--report-primary-color)
}

.report-question-feedback-instructors ul {
    list-style: disc !important;
    padding-left: 20px !important;
}

.report-question-feedback-instructors ul li {
    font-size: var(--report-regular-font-size);
    font-weight: var(--report-light-font-weight);
    color: var(--report-primary-color);
    width: 80%;
}

.rank-open-questions-ranked-contaienr {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* text-align: center; */
    padding-bottom: 20px;
    justify-content: space-between;
}

.rank-open-questions-ranked-contaienr 
.rank-open-questions-ranked-label {
    background-color: #50B685;
    color: #FFFFFF;
    padding: 10px 28px;
    border-radius: 30px;
    font-size: var(--report-big-font-size);
    font-weight: var(--report-regular-font-weight);
}

.rank-open-questions-ranked-contaienr 
.rank-open-questions-ranked-edit-label {
    font-size: var(--report-regular-font-size);
    font-weight: var(--report-regular-font-weight);
    text-decoration: underline;
    margin-left: 22px;
    cursor: pointer;
}


.rank-open-questions-ranked-contaienr 
.rank-open-questions-ranked-next-button { 
    font-weight: var(--report-regular-font-weight);
    background-color: var(--report-primary-color);
    font-size: var(--report-regular-font-size);
    border-radius: 30px;
    padding: 10px 28px;
    color: #fff;
}

.rank-open-questions-ranked-contaienr 
.rank-open-questions-ranked-next-button:disabled {
    background-color: var(--report-tertiary-color);
    cursor: not-allowed;
}