/*
 * SkillsSection
 */
.review-skills-section-single {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.review-skills-section-name {
    align-self: center;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-size: 16.8px;
    color: #262626;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.review-skills-section-score {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-items: center;
}

.review-skills-section-score
.review-skills-section-icon {
    width: 40px;  
}

.review-skills-section-score
.review-skills-section-icon img {
    margin: auto;
    width: 16px;
}

.review-skills-section-score
.review-skills-section-slider {
    width: 80%;
} 

.review-skills-section-score
.review-skills-section-slider-value {
    width: 40px;
    text-align: center;
}

.review-skills-section-score
.review-skills-section-icon-condition {
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
}

/*
 * SkillsTitleContent 
 */
.review-skills-roadmap-content {
    padding: 40px 0;
}

.review-skills-roadmap-single {
    display: flex;
    margin-bottom: 6px;
}

.review-skills-roadmap-single img {
    margin-right: 4px;
}

.review-skills-roadmap-single span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.review-skills-roadmap-button {
    background-color: #262626 !important;
    border-radius: 21px !important;
}