.building-blocks-container {
    margin: 18px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.building-blocks-dropdowns {
    display: flex;
    flex-direction: row;
}

.building-blocks-dropdowns > * {
    flex: 1;
    margin-right: 6px;
}