.candidate-profile-container {
    display: flex;
    flex-direction: column;
}

.candidate-profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.candidate-profile-info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.candidate-profile-play-video {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.candidate-profile-play-video img { 
    width: 17px;
    height: 17px;
}

.candidate-profile-play-video span {
    margin-left: 6px;
    font-size: var(--report-small-font-size);
    color: var(--report-secondary-color);
    font-weight: var(--report-bold-font-weight);
}

.candidate-profile-phone, 
.candidate-profile-email,
.candidate-profile-linkedin {
    display: flex;
    flex-direction: row;;
    margin-top: 10px;
    text-decoration: none;
}

.candidate-profile-phone span, 
.candidate-profile-email span,
.candidate-profile-linkedin span {
    font-size: var(--report-small-font-size);
    font-weight: var(--report-light-font-weight);
    color: var(--report-primary-color);
    margin-left: 6px;
}

.candidate-profile-linkedin {
    cursor: pointer;
}

.candiate-profile-video-container {
    position: relative;
    width: 100px;
}

.candidate-profile-play-icon {
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: 5px;
}

/* CircleVideo */

.circle-video-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden !important;
    position: sticky;
    border: 1px solid #26262650;
    cursor: pointer;
}

.circle-video-wrapper {
    width: 180px;
    height: 180px;
    overflow: hidden !important;
    position: absolute;
    bottom: -82%;
    left: -32%;
}

.circle-video {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    height: 100px;
}