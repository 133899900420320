.wizard-define-the-role-hrm-section {
    display: flex;
    flex-flow: column;
}   

.wizard-define-the-role-hrm-section
.wizard-define-the-role-hrm-section-textfields {
    display: flex;
    flex-flow: row;
    margin-top: 32px;
}
.wizard-define-the-role-hrm-section
.wizard-define-the-role-hrm-section-textfields :first-child{
    margin-right: 14px;
}


.wizard-define-the-role-role-quali-section {
    margin-top: 30px;
    max-width: 600px;
}

.wizard-define-the-role-role-exp-section {
    display: flex;
    flex-wrap: wrap;
}

.wizard-define-the-role-role-exp-section > * {
    /* flex: 25%;
    width: 220px !important; */
    margin-right: 45px;
    margin-bottom: 18px;
}

.wizard-define-the-role-role-quali-section > * {
    margin-bottom: 18px;
}