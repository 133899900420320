.review-step-title-header {
    font-size: 18px;
    font-weight: bold;
    margin-right: 6px;
    width: fit-content;
}

.review-step-job-description {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
}

.margin-top-wizard-review {
    margin-top: 22px;
}

.review-step-card-container {
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: flex-end !important;
}

.reivew-step-requirements-text {
    font-weight: 200;
    font-size: 12px;
}

.review-step-custom-skill-card {
    font-size: 12px;
    margin-right: 6px;
    margin-top: 6px;
    margin-bottom: 0;
    border: 1px solid #999999;
    padding: 4px 14px;
    border-radius: 50px;
    color: #000;
    font-weight: 200;
    background-color: transparent;
    cursor: unset !important;
}

.review-step-custom-skill-card:hover {
    background-color: transparent;
    border: 1px solid #999999;
}

.custom-card-cursor-pointer {
    cursor: pointer !important;
}

.review-step-requirements-section {
    margin-top: 18px;
}

.review-step-sub-header {
    font-size: 16px;
    color: #FF6E30;
    margin-right: 6px;
    align-self: center;
}

.review-step-sub-header-container {
    display: flex !important;
    flex-flow: row !important;
    align-items: center !important;
}

.review-step-hrms-section {
    display: flex;
    flex-flow: column;
    margin-top: 18px;
}

.edit-template-component-container {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
}

.review-step-edit-link {
    font-size: 12px !important;
    color: #999999 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    font-weight: 300 !important;
}

.review-step-title-edit-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.review-step-title-edit-input-button {
    margin-left: 8px;
}

.other-titles-input-wrapper {
    width: 400px;
}

.other-titles-confiramtions-controllers { 
    margin-top: 10px;
}