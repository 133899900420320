.custom-wizard-loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: white;
}

.fade-animation-loading {
    opacity: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: white;
    animation: fade-out-animation-loading 0.5s ease 1s forwards;
}

.fade-animation-loading img, .custom-wizard-loading-container img {
    width: 400px
}

@keyframes fade-out-animation-loading {
    0%{
        opacity: 1;
        visibility: block;
        display: block;
    }
    100%{
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        display: none;
    }
}