@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap');

.review-highlights-section-decription {
    width: 70%;
    font-family: 'Mulish', sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #262626;
    padding-top: 4px;
}

.review-highlights-section-insights {
    display: flex;
    margin-top: 26px;
    justify-content: space-between;
    width: 100%;
}

.review-highlights-section-insights-single {
    color: #262626;
    display: flex;
    flex-flow: column;
}

.review-highlights-section-insights-single h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 6px;
}

.review-highlights-section-insights-single span {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
}

.review-highlights-section-insights-single p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 6px;
}

.review-ihghlights-section-seperate-line {
    width: 1.8px;
    height: 130px;
    background-color: #FF8E5A;
    margin: 0 31px;
}

/*
 * Adapt to small screens (1366)
 */

 @media only screen and (max-width: 1367px) {
    .review-highlights-section-decription {
        width: 95%;
    }
 }