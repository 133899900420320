.cv-modal {
    min-width: 700px; 
    display: flex; 
    justify-content: center;
}

.downlaod-modal {
    display: flex;
    flex-direction: column;
    max-width: 700px; 
    padding: 26px 32px;
    align-items: center;
}

.downlaod-modal-header {
    font-size: 18px;
    color: #333333;
}

.downlaod-modal-downloads-buttons {
    display: flex;
    flex-direction: row;
    width: 90px;
    justify-content: space-between;
    margin-top: 24px;
}

.downlaod-modal-downloads-buttons a {
    text-decoration: none;
    color: #000;
    font-size: 12px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    cursor: pointer;
}

.downlaod-modal-downloads-buttons a img {
    margin: auto;
    margin-top: 6px;
}

.downlaod-modal-share {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.downlaod-modal-share-header {
    color: #949494;
    font-size: 14px;
}

.downlaod-modal-share-input {
    border: 1px solid #9D9D9D30;
    border-radius: 5px;
    padding: 18px 26px;
    margin-top: 10px;
    display: flex;
    width: 560px;
    flex-direction: row;
}

.downlaod-modal-share-input p {
    color: #949494;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.downlaod-modal-share-input svg {
    margin-left: 12px;
    cursor: pointer;
    color: #333;
}