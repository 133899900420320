.notification-center-button:hover {
    background-color: transparent !important;
}

.notification-center-button {
    min-width: 0px !important;
}

.notification-center-icon {
    position: relative;
}

.notification-center-icon 
.notification-center-new-notifications {
    width: 8px;
    height: 8px;
    background-color: #FF0000;
    border-radius: 15px;
    top: 2px;
    left: 13px;
    position: absolute;
}

.notification-center-header {
    background-color: #F3F4F7;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 10px 18px;
}

.notification-center-header 
.notification-center-header-text {
    font-size: 14px;
    font-weight: 700;
}

.notification-center-header 
.notification-center-header-mark-as-read {
    font-size: 12px;
    font-weight: 400;
    color: #757575;
    align-self: center;
    cursor: pointer;
}

.notification-dropdown {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}