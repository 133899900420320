.no-suggestions-message {
    margin-top: 10px;
    font-family: 'Mulish';
    font-size: 12px;
    color: #333333;
}

.no-suggestions-message span {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.wizard-custom-title-input {
    width: 400px;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    height: 38px;
    align-items: center;
}

.wizard-custom-title-input input,  .wizard-custom-title-input input:focus{
    outline: none;
    box-shadow: none;
    padding: 2px 8px;
    width: -webkit-fill-available;
    color: #000;
}

.wizard-other-position-titles {
    margin-top: 8px;
    display: flex;
    flex-flow: wrap;
}

.wizard-other-position-names-section {
    display: flex;
    flex-flow: column;
    margin-top: 28px;

}

.wizard-other-position-names-section
.wizard-other-position-names-title {
    font-family: 'Mulish';
    font-size: 12px;
}

.wizard-other-position-names-section
.wizard-other-position-names-suggestions {
    margin-top: 12px;
    display: flex;
    flex-flow: wrap;
}

.suggestion-card-container {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 12px;
    background-color: #000;
    color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 14px;
    border: 1px solid #333333;
    border-radius: 50px;
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-right: 6px;
    margin-bottom: 8px;
    transition: all 0.1s ease-in-out;
}

.suggestion-card-container:hover {
    background-color: #FF8E5A;
    border: 1px solid #FF8E5A;
}

.suggestion-card-container svg{
    font-size: 12px;
    margin-left: 6px;
}

.max-lines {
    display: block;/* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
}

.suggestion-card-selected{
    background-color: #FF8E5A;
    border: 1px solid #FF8E5A;
}