.unboxable-button {
    padding: 0.5em 1em;
    border-radius: 30px;
    font-family: 'Mulish';
}

.unboxable-button-size-small {
    font-size: 12px;
}

.unboxable-button-size-medium {
    font-size: 14px;
}


.unboxable-button-color-primary {
    background-color: #5a7bff;
    color: #fff;
}

.unboxable-button-color-grey {
    background-color: #D9D9D9;
    color: #000000;
}

.unboxable-button-color-orange {
    background-color: #F7985E;
    color: #fff;
}

.unboxable-button-color-white {
    background-color: #FFF;
    color: #000000;
}

.unboxable-button-color-black {
    background-color: #333;
    color: #FFF;
}


.unboxable-button-color-success {
    background-color: #50B685;
    color: #fff;
}

.unboxable-button-color-danger {
    background-color: #F06969;
    color: #fff;
}

.unboxable-button-color-primary:disabled,
.unboxable-button-color-grey:disabled,
.unboxable-button-color-success:disabled,
.unboxable-button-color-danger:disabled {
    opacity: 0.7;
    cursor: no-drop;
}

.unboxable-button-direction-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.unboxable-button-direction-left img {
    margin-right: 10px;
}

.unboxable-button-direction-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.unboxable-button-direction-right img {
    margin-left: 10px;
}