.description-section-upload-text {
    font-size: 14px;
    font-weight: bold;
}

.description-section-upload-text-upload {
    text-decoration: underline;
    cursor: pointer;
}

.description-section-rtf-container {
    margin-top: 18px;
}