.components-stepper-container {
    display: flex;
    font-family: 'Mulish';
    flex-direction: column;
}

.components-stepper-navigation-footer {
    bottom: 0;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    /* align-items: flex-end; */
    margin-top: 40px;
}

.components-stepper-button {
    font-size: 12px;
    font-family: 'Mulish';
}

.components-stepper-button svg {
    font-size: 12px;
}

.components-stepper-next-button {
    background-color: #333333;
    color: white;
    padding: 6px 22px;
    border-radius: 0 15px 15px 0;
}

.components-stepper-next-button-radius {
    border-radius: 15px !important;
}

.components-stepper-prev-button {
    background-color: #EDEDED;
    color: #333333;
    padding: 6px 8px;
    border-radius: 15px 0 0 15px;
}

.progress-bar-label {
    font-size: 12px;
    font-family: 'Mulish';
}

.components-stepper-step-name-tracker {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-family: 'Mulish';
    align-items: baseline;
}

.step-name-tracker-bold {
    font-weight: 700;
}

.components-stepper-content-container {
    margin-top: 4vh;
    min-height: 56vh;
}

.step-name-tracker-step-name-single {
    cursor: pointer;
}

.component-stepper-skip-button {
    background-color: transparent;
    color: #333333;
    margin-left: 12px;
    font-size: 12px;
}

.component-stepper-content-navigation-container {
    max-width: 600px;
    /* min-height: 540px; */
}