.main-title {
    font-family: var(--font-family);
    line-height: 32px;
}

.main-title-xl {
    font-size: 30px;
}

.main-title-l {
    font-size: 1.5em;
}

.main-title-r {
    font-size: 1.17em;
}

.main-title-s {
    font-size: 1em
}