.weekly-report-page {
    display: flex;
    flex-direction: column;
    font-family: 'Mulish';
}

.weekly-report-page 
.weekly-report-headers {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.weekly-report-page 
.weekly-report-headers .headers-big {
    font-size: 22px;
    line-height: 46px;
    font-weight: 700;
}

.weekly-report-page 
.weekly-report-headers .headers-big span {
    font-weight: 300;
}

.weekly-report-page
.weekly-report-headers .headers-sub {
    font-size: 12px;
    line-height: 26px;
    font-weight: 400;
    color: #999999;
}

.weekly-report-page
.weekly-report-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 34px;
}

.weekly-report-page
.weekly-report-actions
.actions-loading-button {
    padding: 0 !important;
    min-width: 30px !important;
}

.weekly-report-page
.weekly-report-actions
.actions-loading-button span{
    margin: 0 !important;
}

.weekly-report-page
.weekly-report-actions
.actions-date-button {
    font-family: 'Mulish';
    background-color: #fff;
    font-size: 10px;
    padding: 12px 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
}

.weekly-report-page
.weekly-report-actions
.actions-date-button svg:first-child {
    font-size: 13px;
    margin-right: 8px;
}

.weekly-report-page
.weekly-report-actions
.actions-date-button svg:last-child {
    font-size: 14px;
    margin-left: 8px;
}


.weekly-report-page
.weekly-report-statistics
.statistics-cards {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 20px;
}