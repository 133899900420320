.candidate-name-and-title {
  position: relative;
  width: fit-content;
  margin-top: 50px;
  margin-bottom: 50px;
}

.candidate-name-and-title h1 {
  font-size: 18px;
  border: 0.7px solid #262626;
  padding: 5px 35px;
  border-radius: 20px;
}

.candidate-name-and-title h1 span {
  font-weight: 600;
}