@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900");
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;600;700&display=swap');

/* variables */
:root {
  --font-family: 'Mulish';
  --font-size: 20px;
  --answer-font-size: 18px;
  --font-color: #000;
  --gray-color: #BBB9B9;
  --title-color: #333333;
}
.container2 .sv_main {
  top: 50%;
}

:root > * {
  color: #333333;
  /* font-weight: 200; */
}


/* React-toastify */
.container2 .Toastify__toast-body {
  font-family: var(--font-family);
}

/* Main */
.container2 .sv_main {
  font-family: var(--font-family);
  font-size: var(--font-size);
  background-color: white;
}

/* Question container */
.container2 .sv_bootstrap_css .sv_qstn {
  padding: 0;
}

.container2 .sv_custom_header {
  background-color: white !important;
}

.container2 .sv_qstn > div {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

/* Title */
.container2 h4 > .sv-string-viewer {
  display: flex;
  justify-content: center;
}

.container2 h5 > .sv-string-viewer {
  display: block;
  max-width: 750px;
  text-align: center;
}

.container2 th > span.sv-string-viewer {
  font-size: 18px;
}

/* Text container - small */
.container2 .small {
  margin-bottom: 10px;
}

/* Question > div (margin between question title and buttons */
.container2 .sv_qstn > div {
  /* margin-bottom: 50px; */
  /* float: left; */
}

/* Labels */
.container2 .sv-string-viewer {
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
}

/* Button container */
.container2 .panel-footer {
  display: flex;
  flex-direction: column-reverse; justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
  background-color: white;
}

/* Buttons */
.container2 .nav_button {
  border-radius: 5px !important;
  font-family: var(--font-family) !important;
  font-weight: bold !important;
  font-size: 16px !important;
  padding: 10px 15px 10px 15px !important;
}

.container2 .sv_next_btn {
  background-color: black !important;
  font-weight: normal !important;
  padding: 5px 0px !important;
  margin-top: 20px;
}

.container2 .sv_next_btn[disabled] {
  background-color: #D2CDD0 !important; 
  cursor: context-menu !important; 
}

.container2 .sv_prev_btn {
  display: none;
  background-color: transparent !important;
  color: black !important;
  font-weight: normal !important;
  font-size: 14px !important;
}

.container2 .sv_complete_btn {
  background-color: black !important;
}

.container2 .sv_complete_btn[disabled] {
  background-color: #D2CDD0 !important; 
  cursor: context-menu !important;  
}

.container2 .skip-btn {
  background-color: #FFF;
  border: 0px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 13px;
  margin-right: 5px;
}

/* Textarea container */
.container2 .sv_main.sv_main .sv_qstn textarea {
  min-width: 600px;
  font-family: var(--font-family) !important;
  font-size: var(--answer-font-size) !important;
  resize: none !important;
  border-color: var(--gray-color);
}

.container2 .sv_main.sv_main .sv_qstn textarea:focus {
  border-color: var(--gray-color);
}

/* Input text */
.container2 .sv_qstn .form-control[type="text"] {
  border-bottom: 1px solid var(--gray-color) !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  text-align: center;
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--font-color) !important;
  width: auto;
}

/* Toggle Switch */
.container2 .sv-boolean__switch {
  background: #000 !important;
}

/* File Upload */

/* Checkboxes */
.container2 .checkbox.sv-q-col-1 {
  border: 1px solid var(--gray-color);
  padding: 15px 25px;
  border-radius: 10px;
}

/*.checkbox.sv-q-col-1  {}*/

.container2 .checkbox.sv-q-col-1 input[type="checkbox"] {
  width: 24px;
  height: 24px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;

  -webkit-box-shadow: 0px 0px 0px 1px var(--gray-color);
  -moz-box-shadow: 0px 0px 0px 1px var(--gray-color);
  box-shadow: 0px 0px 0px 1px var(--gray-color);
}

.container2 .checkbox.sv-q-col-1 input[type="checkbox"]:checked {
  background: #333;
}

.container2 .checkbox.sv-q-col-1 .sv-string-viewer {
  font-size: 20px !important;
  color: #333333;
  margin-left: 10px;
}

.container2 fieldset.sv_qcbx .checkbox.sv-q-col-1:not(:first-of-type) {
  margin-top: 20px !important;
}

/* Table Matrix */
.container2 .table.sv_q_matrix {
  border-collapse: separate;
  border-spacing: 0 0.7em;
}

.container2 .table.sv_q_matrix th {
  min-width: 120px;
  max-width: 140px;
}

.container2 table.sv_q_matrix.com-profile-matrix th {
  min-width: 74px;
  max-width: 74px;
}

.container2 .sv_q_matrix {
  margin-top: 20px;
}

.container2 .sv_q_m_cell .sv_q_m_label {
  display: flex !important;
  justify-content: center;
  align-items: center;
  top: 3.5px;
}

.container2 .sv_q_m_cell .sv-string-viewer {
  min-width: 90px !important;
  max-width: 110px !important;
}

/* Radio group */
.container2 .sv_bootstrap_css .sv_qcbc input[type="radio"] {
  margin-right: 10px;
}

.container2 .sv_qcbc.form-inline {
  display: flex !important;
  flex-direction: column !important;
}

/* Radio buttons */
.container2 .sv_q_m_label input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -6px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.container2 .sv_q_m_label input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -6px;
  position: relative;
  background-color: #000;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

/* Ranking */
.container2 .sv-ranking-item__index {
  background-color: rgba(232, 232, 232, 0.55) !important;
  color: black;
}

/* File Upload */
.container2 .sv_q_file {
  display: flex;
  width: "100vw";
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container2 .sv_q_file_input {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 0 !important;
  color: rgba(0, 0, 0, 0) !important;
}

.container2 .sv_q_file_input::-webkit-file-upload-button {
  visibility: hidden;
}

.container2 .sv_q_file_input::before {
  content: 'Browse your files';
  display: inline-block;
  background-color: #FAFAFA;
  border: 1px solid #999;
  border-style: dashed;
  border-radius: 3px;
  padding: 50px 28px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  text-align: center;
  font-weight: 700;
  font-size: 12pt;
  color: #999;
}

.container2 .sv_q_file_input:hover::before {
  background-color: #F7F7F7;
}

.container2 .sv_q_file_input:active::before {
  background-color: #FAFAFA;
}

.container2 .sv_q_file_preview {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container2 .sv_q_file_preview a {
  font-size: 14px;
  color: black;
}

.container2 .sv_q_file_remove_button {
  background-color: black !important;
  border-radius: 6px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-top: 15px;
  margin-bottom: 15px;
  display: none !important;
}

.container2 .sv_q_file_remove {
  margin-top: 15px;
  font-size: 15px;
  font-weight: bold;
}

.container2 .sv_q_file_remove:hover {
  color: black !important;
}

.container2 .sv_q_file_preview img {
  max-height: 100px !important;
  margin-top: 15px;
}

.container2 .sv_qstn > div > .sv_q_file {
  margin-bottom: -50px !important;
}

/* Image */
.container2 .sv_q_image > .sv_image_image {
  width: 100% !important;
  height: auto !important;
}

/* Image Picker */
.container2 .sv_main.sv_main .sv_q_imgsel.checked label > div {
  background-color: black !important;
}

.container2 .sv_imgsel {
  display: inline-block !important;
}

/* Smartphones (portrait) */
@media only screen and (max-width : 640px) {

  /* Survey container */
  .container2 .sv_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Matrix table */
  .container2 .table.sv_q_matrix tbody tr {
    line-height: 28px;
  }

  .container2 .table.sv_q_matrix tbody tr {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .container2 .sv_q_m_cell .sv_q_m_label {
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-end;
    top: 6px;
    margin-left: 20px !important;
  }

  .container2 .sv_q_m_cell .sv-string-viewer {
    font-weight: 600;
  }

  .container2 .sv_q_m_cell:first-child {
    margin-bottom: 10px !important;
  }

  .container2 .sv_q_m_cell {
    color: var(--font-color) !important;
  }

  /* Remove padding left for text within the row */
  .container2 .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.sv_q_matrix td::after {
    padding-left: 0px !important;
  }

  /* Textarea container */
  .container2 .sv_main.sv_main .sv_qstn textarea {
    min-width: 200px !important;
    max-width: 350px !important;
  }

  /* Radio group */
  /* vertical space between options */
  .container2 .radio.sv-q-col-1 {
    margin-top: 10px;
  }

  .container2 h5 > .sv-string-viewer {
    font-size: 18px;
    line-height: 1.6em;
  }

  .container2 .floating-footer-background {    
    position: fixed;
    height: 90px;
    width: 100%;
    bottom: 74px;
    background-color: #FFF;
    box-shadow: 0px -3px 8px -1px rgba(0, 0, 0, 0.1);
  }

  .container2 .panel-footer.card-footer {
    z-index: 1;
    position: fixed;
    bottom: 105px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }

  .container2 .panel-footer.card-footer .sv_next_btn {    
    margin: 0px;
  }

  .container2 .skip-btn {
    z-index: 1;
    position: fixed;
    bottom: 85px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0px;
  }

  .container2 .sv_container {
    margin-top: 150px;
    margin-bottom: 70px;
  }

}
