
.building-blocks-table {
    background-color: #fff;
    border-radius: 5px;
}

.building-blocks-table thead tr {
    font-family: 'Mulish';
    font-size: 16px;
    color: #333;
    text-align: left;
    padding: 10px 14px;
}

.building-blocks-table thead th span {
    border-left: 1px solid #0005;
    padding: 0px 14px;
}

.building-blocks-table thead th:first-child {
    border-left: none;
}

.building-blocks-table tbody tr {
    border-bottom: 1px solid #0005;
    height: 50px;
    overflow: hidden;
}

.building-blocks-table tbody tr td {
    max-height: 50px;
}