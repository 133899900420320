.cls-1-regular {
    fill: #262626;
}

.cls-2-chosen-talent {
    fill: #5a7bff;
}

.cls-2-chosen-hrm {
    fill: #FF8E5A;
}

.cls-5-hrm-RS {
    clip-path: url(#clip-path-RS);
}

.cls-5-hrm-SV {
    clip-path: url(#clip-path-SV);
}

.cls-5-hrm-R {
    clip-path: url(#clip-path-R);
}

.cls-5-hrm-I {
    clip-path: url(#clip-path-I);
}

.cls-5-hrm-V {
    clip-path: url(#clip-path-V);
}

.cls-5-hrm-S {
    clip-path: url(#clip-path-S);
}

.cls-5-hrm-IV {
    clip-path: url(#clip-path-IV);
}

.cls-5-hrm-RI {
    clip-path: url(#clip-path-RI);
}


.cls-6-hrm {
    stroke: #ff8e5a;
    stroke-miterlimit: 10;
    stroke-width: 1.5px;
}

.cls-7-hrm, .cls-6-hrm {
    fill: none;
}