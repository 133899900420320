
.confirmation-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.confirmation-input-confirm-button {
    margin-left: 8px !important;
}
