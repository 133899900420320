.cv-highlights-section {
    display: flex;
    flex-direction: column;
}

.cv-highlights-headers-container {
    display: flex;
    flex-direction: column;
    width: 242px;
}

.cv-highlights-section-summary {
    display: flex;
    flex-direction: row;
}

.cv-highlights-header {
    font-size: 26px;
    font-weight: var(--report-regular-font-weight);
    margin-bottom: 4px;
}

.cv-highlights-header-text {
    font-size: var(--report-small-font-size);
    color: var(--report-tertiary-color);
    text-transform: uppercase;
    max-width: 166px;
}

.cv-highlights-section-decription {
    font-weight: var(--report-light-font-weight);
    font-size: var(--report-regular-font-size);
    color: var(--report-primary-color);
    width: 70%;
}

.cv-highlights-insights-section {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
}

.extracted-from-text {
    font-size: var(--report-regular-font-size);
    color: var(--report-quaternary-color);
}

.talent-cv-text {
    display: flex;
    flex-direction: row;
    font-size: var(--report-regular-font-size) !important;
    color: var(--report-secondary-color);
    text-decoration: underline;
}

.talent-cv-text img {
    font-size: var(--report-regular-font-size) !important;
    margin-left: 6px;
}

.cv-highlights-section-insights {
    display: flex;
}

.cv-highlights-section-insights-single {
    color: var(--report-primary-color);
    display: flex;
    flex-flow: row;
    align-items: center;
}

.cv-highlights-section-insights-single h3 {
    font-weight: var(--report-regular-font-weight);
    font-size: 36px;
    line-height: 45px;
    font-weight: var(--report-lightest-font-weight);
}

.cv-highlights-section-insights-single span {
    font-weight: 300;
    font-size: var(--report-regular-font-size);
    line-height: 18px;
    color: var(--report-quaternary-color);
    max-width: 116px;
    margin-left: 12px;
}

.cv-ihghlights-section-seperate-line {
    width: 1.8px;
    /* height: 130px; */
    background-color: #FF8E5A;
    margin: 0 31px;
}

.cv-highlights-section-skills-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 44px;
    padding-left: 232px;
    width: 92%;
}

.cv-highlights-section-skill {
    padding: 5px 16px;
    border: 1px solid var(--report-primary-color);
    font-size: var(--report-small-font-size);
    font-weight: var(--report-regular-font-weight);
    color: var(--report-primary-color);
    border-radius: 50px;
    margin: 0 0 10px 8px;
}

/*
 * Adapt to small screens (1366)
 */

 @media only screen and (max-width: 1367px) {
    .cv-highlights-section-decription {
        width: 64%;
    }
 }